import React from 'react';
import Intro from "../../../../src/content/en/read/_intro.mdx";
import TwoPaths from "../../../../src/content/en/read/_two-paths.mdx";
import OurTime from "../../../../src/content/en/read/_our-time.mdx";
import Instructions from "../../../../src/content/en/read/_instructions.mdx";
import Now from "../../../../src/content/en/read/_now.mdx";
import Read from "../../../../src/components/read";
export default {
  React,
  Intro,
  TwoPaths,
  OurTime,
  Instructions,
  Now,
  Read
};