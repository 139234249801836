import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Path from '../../../components/two-paths/path-text';
import PathHero from '../../../components/two-paths/path-hero';
import Marquee from '../../../components/marquee';
export const _frontmatter = {
  "order": 0
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Due strade`}</h2>
    <Marquee mdxType="Marquee">La fine del mondo</Marquee>
    <PathHero id="a" mdxType="PathHero">
      <h3>{`Scelta A`}</h3>
      <p>{`È finita`}<br parentName="p"></br>{`
`}{`Abbassa la testa`}</p>
      <p>{`e`}</p>
      <p>{`scorri`}<br parentName="p"></br>{`
`}{`l’apocalisse`}<br parentName="p"></br>{`
`}{`sul tuo telefono`}</p>
    </PathHero>
    <Path id="a" mdxType="Path">
      <p>{`Stai a guardare come i robot della Silicon Valley rimpiazzano e sostituiscono tutto. Nuove sette mortifere fanno sembrare l’ISIS un gioco da ragazzi. Le autorità emanano una app di geo-localizzazione per spiare in tempo reale immigrati e gli appartenenti al dissenso politico, mentre metafascisti finanziano online i futuri campi di concentramento. I servizi del governo falliscono. I politici ricorrono a misure sempre più draconiane e la Sinistra, ancora, abbaia senza denti.`}</p>
      <p>{`Intanto i ghiacciai si sciolgono, gli incendi divampano, l’uragano di turno affoga un’altra città. Vecchie pestilenze riemergono dal permafrost disgelato. Il lavoro continua, infinito, mentre i ricchi traggono benefici dalle rovine. Alla fine, sapendo di non aver fatto nulla, `}<em parentName="p">{`ci estinguiamo`}</em>{`, condividendo la nostra tomba con tutto il resto della vita sul pianeta.`}</p>
    </Path>
    <Marquee mdxType="Marquee">L’inizio del prossimo mondo</Marquee>
    <PathHero id="b" mdxType="PathHero">
      <h3>{`Scelta B`}</h3>
      <p>{`Respira a pieni polmoni`}</p>
      <p>{`e`}</p>
      <p>{`Preparati per`}<br parentName="p"></br>{`
`}{`un nuovo mondo`}</p>
    </PathHero>
    <Path id="b" mdxType="Path">
      <p>{`Una moltitudine di persone, spazi e infrastrutture preparano il terreno dove territori, forti e autonomi, prendono forma. C’è tutto, per tutti. La terra è spartita per uso comune. La tecnologia non ha più segreti: tutto può essere uno strumento, qualsiasi cosa un’arma. Linee di rifornimento autonome rompono la morsa economica. Reti collaborative si occupano di comunicazione immediata, connettendo coloro che sentono il bisogno di costruire una vita altra.`}</p>
      <p>{`Mentre i governi cadono, i territori autonomi fioriscono col nuovo sentimento che, per essere liberi, dobbiamo legarci a questo pianeta e alla vita che ci circonda. Le enclave del tecno-feudalismo sono saccheggiate delle loro risorse. Agli occhi delle forze contro-rivoluzionarie scarseggianti, poniamo la scelta: `}<em parentName="p">{`all’inferno o nell’utopia?`}</em>{` `}</p>
      <p>{`Entrambe le risposte ci soddisfano.`}</p>
      <p>{`Infine, raggiungiamo il ciglio – sentiamo il pericolo della libertà, il calore del vivere insieme, il miracoloso e l’ignoto – e sappiamo: questa è vita.`}</p>
    </Path>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      