import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {colors, type, media, outline} from 'styles'

const bp = 760

export const IntroWrapper = styled.div`
  position: relative;
  color: ${colors.white};
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1;
  z-index: 1;
  min-height: calc(100vh - 50px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${media(bp, {backgroundPosition: '80% center'})};
`

export const BackgroundWrapper = styled.div`
  background-color: ${colors.orange};
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 100%;
`

export const InhabitLogo = styled.div`
  margin: 0;
  flex-shrink: 1;
  ${media(bp, {marginTop: '30vh'})};

  img {
    width: 40vw;
    margin: auto;
    display: none;
    ${media(bp, {display: 'block'})};
  }
`

export const Choose = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-basis: 100%;
  flex-grow: 1;

  p {
    margin-bottom: 0;
  }

  ${media(
    bp,
    css`
      flex-grow: 0;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-around;
    `
  )};
`

export const YouHaveTo = ({children}) => {
  return (
    <div
      css={css`
        ${media(
          // Orange Banner on Desktop
          bp,
          css`
            padding: 2rem 0;
            margin-top: 2rem;
            background-color: ${colors.orange};
            font-size: 4vw;
            flex: 0 0 100%;
          `
        )};

        & > p {
          font-family: ${type.akzidenz};
          ${outline(colors.white)};
        }
      `}
    >
      {children}
    </div>
  )
}
