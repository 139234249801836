import React from 'react'
import styled from '@emotion/styled'
import {css} from '@emotion/react'
import Instruction from './instruction'
import {type, colors, media, cursors} from 'styles/index'

function reduceChildren(children) {
  let currentSection = 0
  return children.reduce((acc, child) => {
    const {mdxType} = child.props

    if (mdxType === 'h2') {
      acc.push(child)
      return acc
    }

    if (mdxType === 'h3') {
      currentSection++
      acc.push({})
    }

    if (mdxType === 'p') {
      if (!Array.isArray(acc[currentSection][mdxType])) {
        acc[currentSection][mdxType] = []
      }

      if (
        child.props.children.props &&
        child.props.children.props.mdxType === 'a'
      ) {
        acc[currentSection].link = child.props.children
        return acc
      }

      acc[currentSection][mdxType].push(child)
      return acc
    }

    acc[currentSection][mdxType] = child
    return acc
  }, [])
}

const Instructions = ({children, className}) => {
  const steps = reduceChildren(children)
  const h2 = steps.shift()

  return (
    <>
      {h2}
      {steps.map((step, i) => (
        <Instruction
          className={className}
          key={`instruction-${i + 1}`}
          index={i + 1}
          {...step}
        />
      ))}
    </>
  )
}

const StyledInstructions = styled(Instructions)`
  display: grid;
  grid-template-columns: 40px auto 40px;

  & > * {
    grid-column: 2 / -2;
  }

  ${media(
    900,
    css`
      grid-template-columns: 60px repeat(12, 1fr) 60px;
    `
  )};

  ${media(
    1200,
    css`
      grid-template-columns: 130px repeat(12, 1fr) 130px;
    `
  )};

  h3 {
    font-family: ${type.lydia};
    font-size: 3rem;
    color: ${colors.orange};
    margin-top: 6rem;

    ${media(
      900,
      css`
        font-size: 4.3rem;
        grid-column: 2 / -2;
      `
    )};
  }

  p {
    color: ${colors.olive};
    font-size: 1.5rem;
    line-height: 1.8rem;

    em {
      font-family: ${type.pmGrotesk};
      font-style: normal;
      font-weight: normal;
    }

    em:hover {
      cursor: url(${cursors.pill}), auto;
    }
  }
`

export default StyledInstructions
