import Path from "../../../../src/components/two-paths/path-text";
import PathHero from "../../../../src/components/two-paths/path-hero";
import PathImg from "../../../../src/components/two-paths/path-img";
import Marquee from "../../../../src/components/marquee";
import * as React from 'react';
export default {
  Path,
  PathHero,
  PathImg,
  Marquee,
  React
};