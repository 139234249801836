import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {type, colors, media, outline, cursors} from 'styles'
import {MDXProvider} from '@mdx-js/react'

export const Heading = ({title, index}) => (
  <div>
    <h3
      css={css`
        font-family: ${type.lydia};
        font-size: 3rem;
        color: ${colors.orange};
        margin: 6rem 0 2rem;
        position: relative;
        line-height: 0.8;

        ${media(
          900,
          css`
            font-size: 4.3rem;
            grid-column: 2 / -2;
            margin: 10rem 0 4rem;
          `
        )};

        span {
          position: absolute;
          display: block;
          font-family: ${type.akzidenz};
          font-size: 0.4em;
          transform: translateY(0.8em);
          left: -1.3em;
        }

        &:before,
        &:after {
          content: '${title}';
          display: block;
          left: 0;
          margin: 0;
          ${outline(colors.orange)};
        }

        &:before {
          top: 100%;
        }
        &:after {
          bottom: 100%;
        }
      `}
    >
      <span>{index}</span> {title}
    </h3>
  </div>
)

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto 40px;
  font-size: 1.5rem;

  &:before {
    content: '';
    display: block;
    height: 63px;
    margin-top: -63px;
  }

  & > * {
    grid-column: 2 / -2;
  }

  ${media(900, {gridTemplateColumns: '60px repeat(12, 1fr) 60px'})};
  ${media(1200, {gridTemplateColumns: '130px repeat(12, 1fr) 130px'})};
`

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  p {
    color: ${colors.olive};
    line-height: 1.8rem;

    em {
      font-family: ${type.pmGrotesk};
      font-style: normal;
      font-weight: normal;
    }

    em:hover {
      cursor: url(${cursors.pill}), auto;
    }

    a {
      /* font-family: ${type.pmGrotesk}; */
      /* text-transform: uppercase; */
      /* text-decoration: none; */
      /* font-size: 1rem; */
      color: ${colors.orange};

      &:focus,
      &:hover,
      &:active {
        color: ${colors.olive};
      }
    }
  }

  ${media(
    900,
    css`
      height: 90%;
      grid-column: 4 / span 9;
      margin-bottom: -10%;

      p {
        width: calc(50% - 55px);
        max-width: 420px;
      }
    `
  )};
`

export const Circle = ({id, children}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXLink="http://www.w3.org/1999/xlink"
    viewBox="0 0 500 500"
  >
    <defs>
      <path
        d="M243.2, 382.4c-74.8,
    0-135.5-60.7-135.5-135.5s60.7-135.5,135.5-135.5s135.5, 60.7, 135.5,
    135.5 S318, 382.4, 243.2, 382.4z"
        id={id}
      />
    </defs>
    <text
      dy="70"
      textLength="1200"
      css={css`
        fill: ${colors.orange};
        font-family: ${type.lydia};
        text-transform: uppercase;
        letter-spacing: 22px;
      `}
    >
      <textPath xlinkHref={`#${id}`}>{children}</textPath>
    </text>
  </svg>
)

const Blockquote = ({children, setIsHovering, className}) => {
  const enter = () => {
    setIsHovering(true)
  }
  const leave = () => setIsHovering(false)
  const components = {
    em: (props) => <em onMouseEnter={enter} onMouseLeave={leave} {...props} />,
  }
  return (
    <div className={className}>
      <MDXProvider components={components}>{children}</MDXProvider>
    </div>
  )
}

export const Vignette = styled(Blockquote)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media(900, {gridColumn: '2 / span 10'})};

  p {
    color: ${colors.olive};
    font-family: ${type.pmGrotesk};
    line-height: 1.2;
    ${media(900, {fontSize: '2.8rem'})};

    em {
      font-family: ${type.akzidenz};
      font-style: normal;
      font-size: 0.6em;
      text-transform: uppercase;
    }

    em:hover {
      cursor: url(${cursors.pill}), auto;
    }
  }
`
