import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Instructions from '../../../components/instructions';
export const _frontmatter = {
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Instructions;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Instructions`}</h2>
    <h3>{`1. Find Each Other`}</h3>
    <p>{`We’ve been raised in a culture of isolation and defeat, where our potential is reduced to meeting the economy's demands. Buried beneath our own personal worries, our own bills, and our own fears, we are forced to look out only for ourselves. `}<em parentName="p">{`But we are capable of a different life`}</em>{`.`}</p>
    <p><em parentName="p">{`To begin, eliminate isolation`}</em>{`. Cut through the bullshit. Turn to those closest to you and say you need a life in common. Ask what it would be like to face the world together. What do you have? What do you need? Take an inventory of your collective skills, capacities, and connections. Make decisions that will increase your strength. Establish the basis for a life in common.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Imagine a life that reaches past your individual borders. You change the way you move through your environment to intentionally come into contact with others. Fleeting encounters become real relationships. You wander through your neighborhood, stopping by friends' houses on your way to the cafe. You meet up nightly at the park to work out. You walk each other home. You share each other's cars. You go camping and learn how to start a fire together. You pool money for a collective rainy day. The idea of private property gets blurred. You begin to understand yourselves as something more `}<em parentName="p">{`decisive`}</em>{` than a group of friends.`}</p>
    </blockquote>
    <h3>{`2. Establish Hubs`}</h3>
    <p>{`Hubs are points of aggregation, centers of activity. Creating a hub is the logical next step to finding each other. We need dedicated spaces to get organized and to give ourselves time together. Hubs bring together the people, resources, and shared spirit necessary to create the foundation for a life in common.`}</p>
    <p><em parentName="p">{`Pool resources, target an area, and start a hub`}</em>{`. Rent a space in the neighborhood. Build a structure in the forest. Take over an abandoned building or a vacant piece of land. No space is too small, or too ambitious. Start with what's at hand and then multiply. Use the hub to ground all of your initiatives.`}</p>
    <blockquote>
      <p parentName="blockquote">{`A repurposed storefront hosts `}<em parentName="p">{`weekly dinners`}</em>{` that turn into planning sessions. A collectively-run cafe sets aside profits to incubate other spaces, like a wood shop where carpenters work together to build more than just bookshelves. In a forest outside town, a clearing serves as a gathering spot for weekly fires and martial arts training. Nearby, a small permaculture farm slowly expands to feed those living in town.`}</p>
    </blockquote>
    <h3>{`3. Become Resilient`}</h3>
    <p>{`Our bodies are a mystery to us. Our health is out of our hands. If the lights went out, most of us would remain in the dark. We've been dispossessed of skills, passions, and knowledge. But we aren’t fragile.When we learn new skills or overcome harsh challenges, we wrest back the defining thresholds of our sense of possibility. We are capable of incredible and improbable feats.`}</p>
    <p><em parentName="p">{`Reclaim skills, master them through practice, and share their power`}</em>{`.Reach out to people who have capabilities you want everyone to have.Use hubs to experiment. Prepare for the new normal. Learn to hunt,to code, to heal: increase your collective strength.`}</p>
    <blockquote>
      <p parentName="blockquote">{`A hurricane tears through town—power's out. FEMA is taking its sweet time. A group establishes a hub outside of the flood zone. Cooking large dinners together has given everyone the confidence to operate at scale. Teams move out to gather food in a lawless environment, fighting off racist opportunists who cling to an order of property which has been revoked. One gathers medical supplies from hospitals and pharmacies while another opens up water tanks in apartment buildings. A park occupation brings even more people and resources together. Someone scales a building to place a router powered by kinetic energy. The router establishes a connection with a mesh network to call in reinforcements from other hubs across the territory.`}</p>
    </blockquote>
    <h3>{`4. Share a Future`}</h3>
    <p>{`The time of isolated life is over. We all share the catastrophe; we all share the challenges our epoch poses. We can protest the uneven distribution of medical resources all we want, but care will only be universal and dignified once it is rendered autonomous.`}</p>
    <p><em parentName="p">{`Create collective forms of care`}</em>{`. Get organized with the next twenty years in mind. Ask each other how your needs will change as you age, have children,become disabled, begin to die. Make decisions based on desire. Imagine how spaces can accommodate the dynamic nature of living and fighting. Address the most difficult questions: how to face madness, addiction, interpersonal violence, and traumatic loss. At all costs, protect each other from institutionalization.`}</p>
    <blockquote>
      <p parentName="blockquote">{`An intergenerational network forms to address the whole of living. People think together about how to raise children, how to nurture their agency, how to help them cope with the world as it changes. Care for the aging is organized collectively and reverence for elders’ experiences affirms dignity at each stage of life. Health collectives learn ancestral methods of birth control and abortion to ensure `}<em parentName="p">{`autonomous choice`}</em>{`. Shared emotional intelligence aids those needing a break from the fight and those returning to it. Partisan doctors, herbalists, and shaman make a pact to provide care for the network. Everyone rests easier knowing that the hospital does not have to be their first option. The need for the services of government lessens. With a new orientation to life and to death a historical weight is lifted. Without the anxieties and stress of this civilization, sicknesses begin to disappear. A new capacity for care becomes a common reservoir of strength to face the future together.`}</p>
    </blockquote>
    <h3>{`5. Bring the Fight`}</h3>
    <p>{`Our society slanders people who stand up for what's right. We are told nothing can change, to keep to ourselves, and, above all, to not push back.To cultivate a fighting spirit in our time, we must follow an ethical compass in addition to developing strategic thought and building physical capacity.`}</p>
    <p><em parentName="p">{`Become stronger`}</em>{`. Make yourself capable of force. Learn the art of striking,how anything can become a weapon. Learn to subvert the force of the enemy—go from how a punch that harms a fascist is captured as viral content, to how to collectively incapacitate the enemy by cutting off his communication system. What stands in the path of a new way of life? How can you overcome it, together? What strategic considerations will keep you out of the hands of the enemy?`}</p>
    <p>{`💪 `}<a parentName="p" {...{
        "href": "/tools/inhabit.body_strength_conditioning_guide_2020.pdf"
      }}>{`Download the strength and conditioning guide`}</a></p>
    <blockquote>
      <p parentName="blockquote">{`A network of `}<em parentName="p">{`fightclubs`}</em>{` connects every major city. Experienced members teach grappling and striking alongside basic fitness and stretching. Each club finds its space and builds ties with their community, especially those being cast off from this world. One chapter in the Midwest mobilizes with truckers to resist automation. Together they paralyze I-70 with the help of a geotracking app, block the self-driving trucks, and break open their cargo holds. What is useful is expropriated and the rest turned to ashes. Smoke blinds police cruisers already lost amidst makeshift barricades. The cargo yields a batch of mini-drones, which are sent into defensive flight patterns via a reconfigured app. The hacked drones infiltrate incoming police drones to transmit a virus that freezes their propellers, dropping them harmlessly to the ground. Acting with the chaos, the belligerent truckers and fightclubbers take the offensive and make their escape.`}</p>
    </blockquote>
    <h3>{`6. Expand the Network`}</h3>
    <p>{`We don't need another organization to bring us together to talk about problems, but ways to implement concrete practices to solve them. We need a network that amplifies the power of each project, widens the territory, and refuses to leave the future up to chance.`}</p>
    <p><em parentName="p">{`Find each other at an expanded scale`}</em>{`. Look for the other people also getting organized. Scout out nascent intensities and communal forms and make contact. Reach out, establish communication, visit and meet.Exchange stories and strategies, so our network's cultural memory and operational intelligence grows, building a greater power between us.Create material connections, share or trade resources. Multiply this gesture by thousands.`}</p>
    <p>{`🌲 `}<a parentName="p" {...{
        "href": "https://territories.substack.com/p/hunting-the-hunt"
      }}>{`Read 'Hunting the Hunt: On Recent Expressions of Anishinabe Sovereignty'`}</a></p>
    <blockquote>
      <p parentName="blockquote">{`In one subversive territory, biohackers experimenting with new techniques make innovations in `}<em parentName="p">{`water purification`}</em>{`, a group of indigenous families resists an energy company's enclosure of their sacred land, and an autonomous hub redefines its neighborhood with a patchwork of urban farms. Regular communication between these three projects addresses their shared needs. Water treatment techniques spread between them while autonomous food infrastructure gives rise to abundance. The network is weaponized when the indigenous families call for reinforcements to defend their land. Using encrypted communication to coordinate logistics, thousands of people arrive with resources to aid the nascent struggle.`}</p>
    </blockquote>
    <h3>{`7. Build Autonomy`}</h3>
    <p>{`We've been made to rely on paychecks and stores for our basic existence.We’re dependent on the capitalist system which forces us to either submit or starve. There's no way around this fact: the material organization of the present world is the problem we must overcome.`}</p>
    <p><em parentName="p">{`Deepen the reach of autonomous initiatives`}</em>{`. Build the infrastructure necessary to subtract territory from the economy. Answer questions of collective, material power: how to feed each other, house each other,heal each other. Leverage data and design without falling into the trap that the internet will save us. Form collectives and cooperatives that achieve strategic goals without buying into a vacuous economy. Develop scalable solutions to the problems of energy, distribution, communication and logistics.`}</p>
    <blockquote>
      <p parentName="blockquote">{`A local food distribution hub opens a cooperative grocery on the other side of town. Needing to expand capacity, the nearby farm that grows their vegetables integrates into a bioregional network looking to share a world as well as fresh food. A group of designers and engineers who hate their jobs team up to create an app that coordinates a flexible `}<em parentName="p">{`supply chain`}</em>{` among the farms and distribution points. These efforts lead to an autonomous trade corridor springing up. The growth of the network’s force and the utter disregard for regulations leaves the authorities helpless, as food and people circulate freely along with the spirit of rebellion.`}</p>
    </blockquote>
    <h3>{`8. Destitute Infrastructure`}</h3>
    <p>{`We don’t want to improve life just for a select few—this is a mass exodus from this world. That means addressing the infrastructure that underpins this civilization and repurposing things as we see fit.Some systems will have to be dismantled, like oil pipelines and nuclear plants, while others can be broken open to serve autonomy.`}</p>
    <p><em parentName="p">{`Hack everything`}</em>{`. Go from solving problems the current infrastructure cannot address to requisitioning existing institutions and radically changing their use. Occupy deadening spaces—city halls, schools,shopping malls—and breathe new life into them. Anticipate and intensify strategic fractures. Redirect communications systems. Commandeer supply lines. Seize power without governing.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The proliferation of autonomous health clinics begins to influence the world of medicine on all fronts. Nurses, doctors, and administrators work together to clandestinely siphon hospital supplies to these clinics. When veterans’ hospitals are federally defunded, the autonomous clinics join up with patients and healthcare providers to occupy VA offices around the country. Brutal repression at one occupation sends dozens to a nearby state-run hospital, but when the police attempt to enter urgent care to arrest the injured veterans, they are repelled by sympathetic surgeons and nurses. Autonomous groups are joined by forces overflowing from the occupations, and the hospital and its vital resources are seized for the unfolding insurgency.`}</p>
    </blockquote>
    <h3>{`9. Become Ungovernable`}</h3>
    <p>{`Revolution is a line we trace in the present.`}</p>
    <p>{`It means building autonomy here and now, making government and the economy superfluous. Breaking out of being governed will mean more than winning battle after battle and outmaneuvering political foes. It will rest on our ability to create the lasting foundation for life in common.`}</p>
    <p><em parentName="p">{`Spread secession to all areas of life`}</em>{`. Go on permanent strike, slowly but surely, and take everyone with you. Refuse to be managed, or to manage anyone in turn. Drive a wedge down the center of society. Disavow a lifetime’s worth of cynicism and resentment. Believe that it is all possible.`}</p>
    <p>{`🧱 `}<a parentName="p" {...{
        "href": "https://territories.substack.com/p/dignity"
      }}>{`Read 'Dignity: On the George Floyd Rebellion'`}</a></p>
    <blockquote>
      <p parentName="blockquote">{`Strikes persist, and the dull weight of debt disintegrates as financial capital collapses under growing hostility. Neighborhood assemblies decide how to act in the state of emergency, rebellious soldiers refuse to fire on their own neighborhoods, and “crime” is now relegated to raids on the governed zones. In cities, every day is like a block party. Confiscated cookouts on crowded streets herald a time beyond these remnants of economic life, when shops are primed for a new use in common. At night, bonfires illuminate the distance and the stars in their wisdom reappear to protect us. In the suburbs, a Walmart is now a hub for free goods and getting organized. Truckers and first responders meet to coordinate aid to a flooded territory. In the West, technologists outfit weather balloons with transceivers to amplify the autonomous internet. Labor freed from the economy increases the yield of autonomous farms, and children again learn how to be loyal to the earth.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      