import React from 'react';
import IntroPl from "../../../../src/content/pl/read/_intro.mdx";
import TwoPathsPl from "../../../../src/content/pl/read/_two-paths.mdx";
import OurTimePl from "../../../../src/content/pl/read/_our-time.mdx";
import InstructionsPl from "../../../../src/content/pl/read/_instructions.mdx";
import NowPl from "../../../../src/content/pl/read/_now.mdx";
import ReadPl from "../../../../src/components/read";
export default {
  React,
  IntroPl,
  TwoPathsPl,
  OurTimePl,
  InstructionsPl,
  NowPl,
  ReadPl
};