import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {colors, type, media} from 'styles'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto 40px;
  color: ${colors.olive};

  ${media(
    900,
    css`
      grid-template-columns: 60px repeat(12, 1fr) 60px;
    `
  )};

  ${media(
    1200,
    css`
      grid-template-columns: 130px repeat(12, 1fr) 130px;
    `
  )};

  & > * {
    grid-column: 2 / -2;
  }

  h2 {
    font-family: ${type.lydia};
    font-size: 3rem;
    color: ${colors.orange};
    margin: 6rem 0;

    ${media(
      900,
      css`
        font-size: 4.3rem;
        grid-column: 2 / -2;
      `
    )};
  }

  & > p {
    font-size: 1.5rem;
    line-height: 1.2;

    em {
      font-family: ${type.pmGrotesk};
      font-style: normal;
      font-weight: normal;
    }

    &:last-of-type {
      ${media(600, {marginBottom: '10rem'})};
    }

    ${media(
      900,
      css`
        font-size: 2.8rem;
        grid-column: 2 / span 10;
      `
    )}
  }
`

export default function Now({children}) {
  return (
    <Wrapper>
      {children.map((child, index) => {
        if (child.props.mdxType === 'h2') {
          return <h2 key={index}>{child.props.children}</h2>
        }
        return React.cloneElement(child, {key: index})
      })}
    </Wrapper>
  )
}
