import React from 'react';
import IntroIt from "../../../../src/content/it/read/_intro.mdx";
import TwoPathsIt from "../../../../src/content/it/read/_two-paths.mdx";
import OurTimeIt from "../../../../src/content/it/read/_our-time.mdx";
import InstructionsIt from "../../../../src/content/it/read/_instructions.mdx";
import NowIt from "../../../../src/content/it/read/_now.mdx";
import ReadIt from "../../../../src/components/read";
export default {
  React,
  IntroIt,
  TwoPathsIt,
  OurTimeIt,
  InstructionsIt,
  NowIt,
  ReadIt
};