import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {type, colors, outline, media} from 'styles'
import {useStaticQuery, graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'

function reduceChildren(children) {
  return children.reduce((acc, child) => {
    const type = child.props.mdxType === 'h3' ? child.props.mdxType : 'content'
    if (!Array.isArray(acc[type])) {
      acc[type] = []
    }
    acc[type].push(child)
    return acc
  }, {})
}

const bp = 760

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin: 0 30px;

  ${media(
    bp,
    css`
      grid-column-end: -1;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      margin: 0;
      & > * {
        flex: 1 1 0;
      }
    `
  )}
`

const Heading = styled.h3`
  font-family: ${type.lydia};
  font-size: 2.5rem;
`

const Copy = styled.div`
  ${outline(colors.orange)};
  text-align: center;
  line-height: 1;
  font-size: 2rem;

  ${media(
    bp,
    css`
      font-size: 2.8vw;
    `
  )}

  p {
    position: relative;
    text-transform: uppercase;
    font-family: ${type.akzidenz};
    &:nth-of-type(2) {
      margin-top: -1rem;
    }

    ${media(
      bp,
      css`
        &:nth-of-type(2) {
          margin-top: 0;
        }
      `
    )}
  }
`

const Outlined = ({image, content}) =>
  content.map((line, i) => {
    // loop through each line of copy and
    // only return image after first line
    let img =
      i === 0 ? (
        <GatsbyImage
          alt=""
          image={image}
          key={`img-${i}`}
          css={css`
            ${media(
              bp,
              css`
                display: none;
              `
            )}
          `}
        />
      ) : null
    return [line, img]
  })

const PathHero = ({children, id}) => {
  const {images} = useStaticQuery(graphql`
    {
      images: allFile(
        filter: {name: {regex: "/path/"}, extension: {eq: "jpg"}}
      ) {
        edges {
          node {
            childImageSharp {
              id
              gatsbyImageData(layout: FULL_WIDTH)
            }
            name
          }
        }
      }
    }
  `)

  const {
    node: {
      childImageSharp: {gatsbyImageData},
    },
  } = images.edges.find(({node: {name}}) => name.includes(`path-${id}`))

  const {h3, content} = reduceChildren(children)

  return (
    <Wrapper>
      <Copy>
        <Heading>{h3[0].props.children}</Heading>
        <Outlined content={content} image={gatsbyImageData} />
      </Copy>
      <GatsbyImage
        image={gatsbyImageData}
        alt=""
        css={css`
          display: none;
          ${media(
            bp,
            css`
              display: block;
            `
          )}
        `}
      />
    </Wrapper>
  )
}

export default PathHero
