import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Path from '../../../components/two-paths/path-text';
import PathHero from '../../../components/two-paths/path-hero';
import Marquee from '../../../components/marquee';
export const _frontmatter = {
  "order": 0
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Dos Caminos`}</h2>
    <Marquee mdxType="Marquee">El Fin del Mundo</Marquee>
    <PathHero id="a" mdxType="PathHero">
      <h3>{`Camino A`}</h3>
      <p>{`Se acabó.`}<br parentName="p"></br>{`
`}{`Agacha la cabeza`}</p>
      <p>{`y`}</p>
      <p>{`checa el`}<br parentName="p"></br>{`
`}{`apocalypsis en`}<br parentName="p"></br>{`
`}{`tu teléfono`}</p>
    </PathHero>
    <Path id="a" mdxType="Path">
      <p>{`Observa mientras Silicon Valley reemplaza todo con robots. Nuevos cultos fundamentalistas mortales hacen que ISIS parezca juego de niñxs. Las autoridades lanzan una aplicación de geolocalización para delatar a inmigrantes y disidentes políticos en tiempo real; mientras que los metafascistas financian campos de concentración de manera colectiva. Los servicios gubernamentales fallan. Los Políticos hacen uso de medidas cada vez más Draconianas y la izquierda sigue ladrando sin dientes.`}</p>
      <p>{`Mientras tanto, los glaciares se derriten, incendios salvajes arden, el Huracán LoQueSea ahoga a otra ciudad. Antiguas plagas reemergen de la descongelación del permafrost. Trabajo infinito mientras los ricos se benefician de la ruina. Finalmente, sabiendo que no hicimos nada, `}<em parentName="p">{`perecemos`}</em>{`, compartiendo nuestra tumba con toda la vida del planeta.`}</p>
    </Path>
    <Marquee mdxType="Marquee">El Inicio Del Siguiente</Marquee>
    <PathHero id="b" mdxType="PathHero">
      <h3>{`Camino B`}</h3>
      <p>{`Respira`}</p>
      <p>{`y`}</p>
      <p>{`prepárate para`}<br parentName="p"></br>{`
`}{`un nuevo mundo`}</p>
    </PathHero>
    <Path id="b" mdxType="Path">
      <p>{`Una multiplicidad de gente, espacios e infraestructuras cimentan la base donde territorios autónomos y poderosos toman forma. Todo para todxs. La tierra es entregada al uso en común. La tecnología es crackeada y liberada Todo una herramienta, todo un arma. Líneas de suministro autónomas rompen la estrangulación económica. Redes inte- gradas proveen comunicación en tiempo real a aquellxs quienes sienten que una vida diferente debe ser construida.`}</p>
      <p>{`Mientras los gobiernos fallan, los territorios autónomos prosperan con un nuevo sentido de que para ser libres, debemos estar atadxs a esta tierra y a la vida en ésta. Enclaves de techno- feudalismo son saqueados por recursos. Confrontamos las menguantes fuerzas de la contrarrevolución con las opciones: `}<em parentName="p">{`al infierno o a la utopía`}</em>{`?`}</p>
      <p>{`Ambas respuestas nos satisfacen.`}</p>
      <p>{`Finalmente, llegamos al borde – sentimos el peligro de la libertad, el abrazo de vivir juntxs, lo milagroso y lo desconocido – y saber: esto es vida.`}</p>
    </Path>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      