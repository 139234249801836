import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Instructions from '../../../components/instructions';
export const _frontmatter = {
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Instructions;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Istruzioni`}</h2>
    <h3>{`1. Trovarsi`}</h3>
    <p>{`Siamo state cresciute in una cultura di solitudine e sconfitta, dove il nostro potenziale è ridotto al venire incontro alle domande di mercato. Rimaniamo sepolti sotto le nostre preoccupazione personali, le nostre bollette, e le nostre paure, siamo obbligati a non prenderci cura d’altro che di noi stesse. `}<em parentName="p">{`Ma siamo capaci di avere una vita diversa.`}</em></p>
    <p><em parentName="p">{`Per iniziare, eliminiamo la solitudine.`}</em>{` Superiamo le cazzate. Rivolgiti alle persone a te più vicine e dì loro che hai bisogno di una vita messa in comune. Chiedi come potrebbe essere se si affrontasse il mondo insieme. Cosa avete a disposizione? Di cosa avete bisogno? Fate un inventario delle vostre capacità, saperi e rapporti collettivi. Prendete delle decisioni tali che aumentino la vostra forza. Stabilite la base per una vita in comune.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Immagina una vita che si estende oltre i tuoi limiti individuali. Cambi il modo in cui ti muovi nel tuo ambiente per entrare intenzionalmente in contatto con altre persone. Incontri a casaccio diventano vere e proprie relazioni. Vaghi attraverso il tuo vicinato, fermandoti da amici sulla strada verso il bar. Vi riaccompagnate a casa. Condividete le macchine. Andate in campeggio e imparate come accendere un fuoco assieme. Mettete da parte soldi per i tempi magri collettivi. L’idea della proprietà privata inizia a svanire. Cominciate ad avere coscienza di voi stessi come qualcosa di più `}<em parentName="p">{`deciso`}</em>{` e determinato che un gruppo di amici.`}</p>
    </blockquote>
    <h3>{`2. Créer des hubs`}</h3>
    <p>{`Gli hub sono punti di aggregazione, centri di attività. Creare un hub è il prossimo passo logico nel trovarsi. Abbiamo bisogno di spazi dedicati all’organizzarci e a darci del tempo insieme. Gli hub mettono assieme le persone, risorse, e la tensione condivisa necessaria per creare le fondamenta di una vita in comune.`}</p>
    <p><em parentName="p">{`Raccogliete risorse, prendete di mira una zona e date vita a un hub.`}</em>{` Prendete un posto in affitto in quartiere. Costruite una casetta nel bosco. Prendete controllo di un edificio abbandonato o di un lotto di terra abbandonato. Non c’è spazio troppo piccolo, né troppo ambizioso. Iniziate con quello che avete a portata di mano e poi proliferate. Usate l’hub per dare forma e spazio a tutte le vostre iniziative. `}</p>
    <blockquote>
      <p parentName="blockquote">{`Oltre la vetrina di un negozio convertito si tengono `}<em parentName="p">{`cene settimanali`}</em>{` che poi si trasformano in sessioni di pianificazione. Un bar collettivo mette il ricavato da parte per nutrire gli embrioni di altri spazi, come una falegnameria dove si lavora insieme per costruire molto più di semplici librerie. In un bosco fuori città, una radura si appresta a raccogliere settimanalmente persone per un falò e allenamenti di arti marziali. Là vicino, una tenuta a permacultura lentamente si sviluppa per nutrire chi vive in città.`}</p>
    </blockquote>
    <h3>{`3. Diventare resilienti`}</h3>
    <p>{`I nostri corpi per noi sono un mistero. La nostra salute, fuori dalle nostre mani. Se si staccasse la spina, la maggior parte di noi rimarrebbe nel buio. Siamo state espropriate di saperi, passioni e conoscenze. Ma non siamo mica fragili. Quando apprendiamo nuove capacità o superiamo dure sfide, lottiamo contro i veri e proprio limiti che definiscono il nostro senso di possibilità. Siamo ben capaci di imprese incredibili e improbabili.`}</p>
    <p><em parentName="p">{`Riappropriatevi di saperi, padroneggiateli con la pratica e condividetene la forza.`}</em>{` Contattate le persone che dispongono di capacità e saperi che vorreste che tutte le altre avessero. Usate gli hub per sperimentare. Preparate la nuova vita quotidiana. Imparate a cacciare, a cifrare, a curare: puntate ad aumentare la vostra forza collettiva.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Un uragano squarcia la città—si propaga un `}<em parentName="p">{`blackout`}</em>{`. La Protezione Civile si prende tutto il tempo del mondo. Un gruppo allestisce un hub fuori dalla zona disastrata. Le cene collettive cucinate assieme hanno rafforzato la confidenza di tutti nel lavorare su grande scala. Delle squadre si muovono per racimolare cibo in zone senza legge, respingendo opportunisti razzisti che si aggrappano ad un’idea di proprietà ormai abrogata. Una persona accumula materiale medico dagli ospedali e dalle farmacie, mentre un’altra raccoglie l’acqua dalle autoclavi degli appartamenti. Un’occupazione in un parco riunisce ancora più persone e materiale. Qualcuno si inerpica su un edificio per piazzare un router alimentato da energia cinetica. Il router stabilisce una connessione con una rete collaborativa in modo da chiamare rinforzi e aiuti da altri hub sparsi per il territorio.`}</p>
    </blockquote>
    <h3>{`4. Condividere un futuro`}</h3>
    <p>{`È finito il tempo della vita isolata. Tutti noi siamo parte della catastrofe; tutte noi abbiamo parte nelle sfide che la nostra epoca ci impone. Possiamo protestare quanto ci pare e piace contro la distribuzione ineguale delle risorse mediche, ma la cura e la salute saranno universali e dignitose solo una volta che saranno autonome.`}</p>
    <p><em parentName="p">{`Create delle forma collettive di cura.`}</em>{` Organizzatevi tenendo a mente i prossimi vent’anni. Chiedetevi a vicenda come cambieranno le vostre esigenze una volta che invecchiate, avrete dei figli, incorrerete in un handicap, inizierete a morire. Prendete delle decisioni basate sulla soddisfazione dei desideri. Immaginatevi come degli spazi possano accogliere la natura dinamica del vivere e combattere. Imparate a dedicarvi alle domande più spinose: come affrontare la pazzia, le dipendenze, la violenza tra le persone e i traumi per le perdite. Proteggetevi l’un l’altra, a tutti i costi, dal ricovero nelle strutture del sistema. `}</p>
    <blockquote>
      <p parentName="blockquote">{`Una rete intergenerazionale si forma per affrontare la vita nella sua interezza. Persone ragionano assieme su come crescere i figli, come accrescere il loro operato, come sostenersi nel far fronte al mondo man mano che cambia. La presa in carico degli anziani è organizzata in maniera collettiva e il rispetto per le esperienze delle persone anziane produce dignità a ogni stadio della vita. Collettivi sanitari apprendono metodi contraccettivi e modalità d’aborto ancestrali per assicurare la possibilità di una scelta autodeterminata. Gruppi di sostegno all’intelligenza emotiva si prendono cura di coloro che hanno bisogno di una pausa dai combattimenti e di coloro che ci stanno per tornare. Dottori partigiani, naturopate e sciamani stringono un patto per occuparsi della salute della rete di persone. Tutti dormono sonni tranquilli nel momento in cui sanno che andare all’ospedale non è per forza la loro prima e unica opzione. Così, il bisogno per i servizi statali e pubblici viene meno. Dando una nuova direzione alla vita e alla morte, si alleggerisce un peso dalla portata storica. Senza le ansie e lo stress che questa civiltà si porta dietro, molte malattie iniziano a sparire. La nuova estensione del concetto e delle pratiche di cura diventa un terreno comune da cui trarre la forza per affrontare assieme il futuro.`}</p>
    </blockquote>
    <h3>{`5. Contrattaccare`}</h3>
    <p>{`La nostra società denigra le persone che lottano per ciò che è giusto. Ci viene detto che niente potrà cambiare, di pensare a noi stessi e soprattutto di non opporsi e non combattere i cambiamenti. In questo momento storico, per coltivare uno spirito combattente dobbiamo seguire una bussola etica, oltre a sviluppare un pensiero strategico e costruire forza e capacità fisica.`}</p>
    <p><em parentName="p">{`Diventate più forti.`}</em>{` Rendetevi capaci di esprimere questa forza. Imparate l’arte di colpire, come tutto possa diventare un’arma. Imparate e sovvertire le forze del nemico—da come un pugno in faccia a un fascista possa essere catturato e diffuso come contenuto virale, a come collettivamente rendere inoffensivo un nemico bloccando il suo sistema di comunicazioni. Cosa si mette di traverso sulla strada verso una nuova forma di vita? Come potete superare questo ostacolo, assieme? Quali mosse strategiche vi terranno fuori dalle mani del nemico?`}</p>
    <blockquote>
      <p parentName="blockquote">{`Tutte le città più grosse sono connesse da una rete di fightclub. Le persone con più esperienza insegnano la lotta a terra e i fondamentali del colpire l’avversario, in parallelo ad un preparazione fisica e allo stretching. Ogni club si prende il suo spazio e si radica con la comunità circostante, soprattutto con quelle person che vengono sempre più emarginate da questo mondo. Un gruppo del Midwest si mobilita con i camionisti per opporsi e resistere all’automazione. Insieme riescono a paralizzare una grande superstrada con l’aiuto di app di geotracciamento, a bloccare i tir a pilota automatico e a forzare gli sportelli del carico. Tutto ciò che può servire viene prelevato, il resto dato alle fiamme. Da qui il fumo acceca le jeep degli sbirri, che già si perdevano tra le barricate arrangiate sul momento. Il carico rivela una serie di mini droni, che vengono quindi mandati in volo con schemi di pattugliamento difensivo attraverso una app riconfigurata. Questi droni hackerati infiltrano i droni della polizia in arrivo, trasmettendo un virus che blocca i loro motori e li fa cadere miseramente a terra. Usando il caos della situazione, i camionisti rivoltosi e i combattenti dei club prendono l’offensiva e riescono a fuggire.`}</p>
    </blockquote>
    <h3>{`6. Allargare la rete`}</h3>
    <p>{`Non abbiamo bisogno dell’ennesima organizzazione per riunirci e parlare dei nostri problemi, ma di implementare pratiche concrete atte a risolverli. Abbiamo bisogno di una rete che faccia da cassa di risonanza alla potenza di ogni progetto, ampli la zona di diffusione e rifiuti di lasciare il futuro al caso.`}</p>
    <p><em parentName="p">{`Pensate a trovarvi su grande scala.`}</em>{` Cercate altre persone che si stanno organizzando. Perlustrate le nascenti intensità e le forme di messa in comune attorno a voi ed entrateci in contatto. Aprite un dialogo, stabilite una comunicazione, andate a conoscere e incontrare. Scambiate storie e strategie, così da accrescere la memoria storica della rete e l’intelligenza pratica, mirando a costruire tra noi una capacità di forza maggiore. Create connessioni materiali, condividete o scambiate risorse. Moltiplicate questi gesti per mille.`}</p>
    <blockquote>
      <p parentName="blockquote">{`In un territorio sovversivo, degli biohacker sperimentano tecniche nuove e trovano modi innovativi per purificare l’acqua, un gruppo di famiglie indigene resiste l’accerchiamento della loro terra sacra da parte di una multinazionale dell’energia, mentre un hub autonomo si riappropria del vicinato tappezzandolo di orti urbani. La comunicazione costante tra questi tre progetti riesce a sostenere i loro bisogni condivisi. Le tecniche per il trattamento dell’acqua si diffondono tra loro mentre l’infrastruttura alimentare autonoma porta all’abbondanza. Quando le famiglie indigene chiamano rinforzi per difendere la terra,  la rete si arma. Usando comunicazioni criptate per coordinarsi logisticamente, migliaia di persone arrivare con materiali per appoggiare la lotta nascente.`}</p>
    </blockquote>
    <h3>{`7. Costruire l’Autonomia`}</h3>
    <p>{`Siamo state portate ad affidarci a buste paga e negozi per portare avanti le nostre esistenze di base. Siamo dipendenti dal sistema capitalista che ci obbliga alla sottomissione o alla fame. Non c’è da girarci attorno: dobbiamo porci il problema dell’organizzazione materiale del mondo presente e superarlo.`}</p>
    <p><em parentName="p">{`Estendete la portata delle iniziative autonome.`}</em>{` Costruite l’infrastruttura necessaria per sottrarre territorio all’economia. Ponetevi e rispondete a domande sulla potenza collettiva e materiale: come avere da mangiare per tutti, come permettere che tutti abbiano una casa, come curarsi. Usate a vostro vantaggio le informazioni e progettate senza cadere nella trappola dell’internet che ci salverà tutti. Date vita a collettivi e cooperative che si pongono e raggiungono obbiettivi strategici senza investire in un’economia vuota. Pensate a sviluppare soluzioni riproducibili su scala ai problemi di energia, distribuzione, comunicazione e logistica.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Un hub locale di distribuzione alimentare apre un mercato cooperativo dall’altra parte della città. Avendo bisogno di estendere la propria capacità di portata, i campi vicini che portano loro le verdure decidono di integrarsi a una rete regionale, provando a condividere un mondo oltre a cibo di qualità. Un gruppo di ingegneri e progettisti che odiano il loro lavoro si riuniscono per creare una app che coordini una catena di rifornimenti flessibile tra le tenute in campagna e i punti di distribuzione. Questi sforzi si concatenano dando vita a un fertile corridoio di scambio. La crescita di forza della rete e la totale noncuranza delle regolamentazioni lasciano le autorità incapaci di reagire, mentre cibo e persone circolano liberamente sospinte dallo spirito di ribellione.`}</p>
    </blockquote>
    <h3>{`8. Destituire l’infrastruttura.`}</h3>
    <p>{`Non vogliamo migliorare la vita per una manciata di eletti: si tratta di un’evasione di massa da questo mondo. Questo significa affrontare l’infrastruttura che soggioga questa civilizzazione e riconvertire le cose come ci sembra opportuno. Alcuni sistemi dovranno essere smantellati, come gli oleodotti o le centrali nucleari, mentre altri possono essere messi prima a nudo e poi al servizio dell’autonomia.`}</p>
    <p><em parentName="p">{`Hack everything.`}</em>{` Spaziate dal risolvere problemi che l’infrastruttura attuale non riesce ad affrontare al requisire istituzioni esistenti cambiandone radicalmente l’uso. Occupate spazi morenti—municipi, scuole, centri commerciali—e infondete loro una nuova vita. Anticipate e allargate fratture strategiche. Reindirizzate sistemi di comunicazione. Prendete il controllo di linee di rifornimento. Prendete il potere, senza governare. `}</p>
    <blockquote>
      <p parentName="blockquote">{`La proliferazione di centri autonomi di salute inizia a influenzare su tutti i fronti il mondo della medicina. Infermieri, dottoresse e amministratori lavorano assieme clandestinamente per redirigere i rifornimenti degli ospedali verso questi centri. Nel momento in cui gli ospedali dei veterani (iii) vengono de-finanziati dal governo federale, i centri autonomi si uniscono con i pazienti e gli erogatori di salute per occupare gli uffici amministrativi (iii) nel Paese. La violenta repressione verso un’occupazione manda dozzine di persone ad un ospedale di Stato, ma quando la polizia tenta di entrare nelle terapie intensive per arrestare i feriti viene respinta da chirurgi solidali e infermiere. Gruppi autonomi aggregano forze in continuo aumento provenienti dalle occupazioni, e l’insurrezione in via d’evoluzione s’impadronisce dell’ospedale, con le sue vitali risorse e materiali.`}</p>
    </blockquote>
    <h3>{`9. Diventare ingovernabili`}</h3>
    <p>{`La rivoluzione è una linea che tracciamo nel presente.`}</p>
    <p>{`Significa costruire l’autonomia qui e ora, rendendo il governo e l’economia superflui. Rompere la normalità dell’essere governati avrà più significato del vincere battaglia dopo battaglia e superare in astuzia i nemici politici. Dipenderà dalla nostra abilità di creare delle fondamenta durevoli per la vita in comune.`}</p>
    <p><em parentName="p">{`Allargate la secessione a tutti gli aspetti della vita.`}</em>{` Un passo alla volta spingete per scioperi permanenti, portando con voi chiunque incontriate. Rifiutatevi di essere gestiti o di gestire a vostra volta qualcuno. Incuneatevi al cuore della società e alzate un muro tra voi e chi vi si oppone. Ripudiate una vita piena di cinismo e rancore. Credete fino in fondo che tutto questo sia possibile.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Gli scioperi vanno avanti, e il tedioso peso del debito si disintegra mentre il capitale finanziario crolla sotto l’ostilità crescente. Assemblee di quartiere decidono come muoversi in caso in emergenza, militari disertori si rifiutano di sparare sul proprio vicinato, e la “criminalità” si limita a incursioni nelle zone ancora governate. Nelle città, ogni giorno è come una festa di paese. Dalle merci recuperate si fanno barbecue su strade affollate, segno di un tempo oltre queste macerie di vita economica, dove i negozi sono arrangiati per un nuovo uso comune. Di notte, si intravedono dei falò in lontananza e le stelle riappaiono con la loro saggezza a proteggerci. Nelle periferie, un Walmart diventa un hub per beni redistribuiti gratuitamente e per organizzarsi. Camionisti e  primi soccorsi s’incontrano per coordinare gli aiuti ad un territorio colpito da un’alluvione. Nell’Ovest tecnologi approntano con trasmettitori dei palloni meteorologici per amplificare la portata della rete internet autonoma. Il lavoro, liberato dall’economia, aumenta la rendita dei campi autonomi e i bambini imparano di nuovo a essere fedeli alla terra.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      