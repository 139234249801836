import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Path from '../../../components/two-paths/path-text';
import PathHero from '../../../components/two-paths/path-hero';
import PathImg from '../../../components/two-paths/path-img';
import Marquee from '../../../components/marquee';
export const _frontmatter = {
  "order": 0
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Two Paths`}</h2>
    <Marquee mdxType="Marquee">The End of the World</Marquee>
    <PathHero id="a" mdxType="PathHero">
      <h3>{`Path A`}</h3>
      <p>{`It's over.`}<br parentName="p"></br>{`
`}{`Bow your head`}</p>
      <p>{`and`}</p>
      <p>{`phone scroll`}<br parentName="p"></br>{`
`}{`through the`}<br parentName="p"></br>{`
`}{`apocalypse`}</p>
    </PathHero>
    <Path mdxType="Path">
      <p>{`Watch as Silicon Valley replaces everything with robots.
New fundamentalist deathcults make ISIS look like child’s play.
The authorities release a geolocation app to real-time snitch on
immigrants and political dissent while metafascists crowdfund the
next concentration camps. Government services fail. Politicians
turn to more draconian measures and the left continues to bark
without teeth.`}</p>
      <PathImg id="a" mdxType="PathImg" />
      <p>{`Meanwhile, glaciers melt, wildfires rage, Hurricane Whatever
drowns another city. Ancient plagues reemerge from thawing
permafrost. Endless work as the rich benefit from ruin.
Finally, knowing we did nothing, `}<em parentName="p">{`we perish`}</em>{`, sharing our
tomb with all life on the planet.`}</p>
    </Path>
    <Marquee mdxType="Marquee">The Beginning of the Next</Marquee>
    <PathHero id="b" mdxType="PathHero">
      <h3>{`Path B`}</h3>
      <p>{`Take a breath`}</p>
      <p>{`and`}</p>
      <p>{`get ready for`}<br parentName="p"></br>{`
`}{`a new world`}</p>
    </PathHero>
    <Path mdxType="Path">
      <p>{`A multiplicity of people, spaces, and infrastructures lay the ground where
powerful, autonomous territories take shape. Everything for everyone.
Land is given over to common use. Technology is cracked open—everything
a tool, anything a weapon. Autonomous supply lines break the economic
stranglehold. Mesh networks provide real-time communication connecting
those who sense that a different life must be built.`}</p>
      <PathImg id="b" mdxType="PathImg" />
      <p>{`While governments fail, the autonomous territories thrive with a new sense
that to be free, we must be bound to this earth and life on it. Enclaves of
techno-feudalism are plundered for their resources. We confront the
dwindling forces of counter-revolution with the option: `}<em parentName="p">{`to hell or utopia?`}</em></p>
      <p>{`Either answer satisfies us.`}</p>
      <p>{`Finally, we reach the edge—we feel the danger of freedom, the embrace of
living together, the miraculous and the unknown—and know: this is life.`}</p>
    </Path>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      