import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Intro from '../../../components/intro';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Intro;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Habiter`}</h1>
    <p>{`Il y a deux`}<br parentName="p"></br>{`
`}{`chemins`}</p>
    <p>{`la fin`}<br parentName="p"></br>{`
`}{`du monde`}</p>
    <p>{`ou le`}<br parentName="p"></br>{`
`}{`début`}</p>
    <p>{`du`}<br parentName="p"></br>{`
`}{`prochain`}</p>
    <p>{`Vous devez choisir`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      