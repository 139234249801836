import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import OurTime from '../../../components/our-time';
import Smudge from '../../../components/smudge';
export const _frontmatter = {
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = OurTime;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Il nostro presente`}</h2>
    <Smudge mdxType="Smudge" />
    <h3>{`Il nostro presente è tumultuoso e potente.`}</h3>
    <p><em parentName="p">{`Sommosse, polarizzazione, la politica in bancarotta come i mercati finanziari`}</em>{` – eppure con la crisi si scorge una possibilità. Quest’epoca ci obbliga a prendere in considerazione quanto ognuno di noi sia un seme di potenziale, quanto gli individui possano seguire le loro più svariate propensioni per aggregarsi ad altri che ne sentono il richiamo. Le persone apprendono capacità perdute e guerrieri rispondono al fuoco del mondo. Contadine e giardinieri sperimentano con l’ agricoltura biologica mentre persone ingegnose e hackers rimodellano e riconfigurano le macchine. Modelle e modelli fuggono dal riflettore e spezzano il pane con radicali e veterane curde che si battono per una vita in comune.`}</p>
    <p>{`Coloro i quali della politica non se ne fanno niente si trovano a tavola per cena a Zuccotti Park, Oscar Grant Plaza, o Tahrir Square, e il barista che a mala pena riesce a procurarsi da mangiare da solo, impara a cucinare per migliaia di persone tutte insieme. Una star di Instagram, la cui ansia solitamente la rinchiude nel suo appartamento, incontra un anziano reduce di guerra a Ferguson, dove vengono battezzati dal gas lacrimogeno e dalla forza collettiva, cominciando a sentire il peso alleggerirsi dalle loro anime.`}</p>
    <p>{`La gente ovunque, superando la grande solitudine, si ribella assieme e trova nuovi modi di vita. Ma quando questi semi escono allo scoperto, sono schiacciati in una frenesia di banalità e paura. Gli spiragli sono violentemente serrati dalla polizia anti-sommossa, da forze di sicurezza privata e da enti di Stato. O ancora peggio, dai solitari – di sinistra o di destra – che non hanno nulla da guadagnare se non un altro like sulla loro merdosa pagina Facebook. Tutto questo mentre politici compiaciuti e amministratori delegati stanno a guardare. Il carattere rivoluzionario della nostra epoca non può essere negato, ma dobbiamo ancora superare l’ostacolo tra noi e la libertà.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Proveniamo da un passato in frantumi, eppure rimaniamo in piedi.`}</h3>
    <p><em parentName="p">{`Il nichilismo della nostra epoca è topologico.`}</em>{` Ovunque si sente la mancanza di fondamenta e di radici. Nella ricerca del potere organizzativo per riparare il mondo, troviamo solo istituzioni colme di debolezza e cinismo. Attivisti e attiviste con buone intenzioni vengono ingurgitati dal corpo smidollato della politica convenzionale, sputati poi fuori come militanti depressi o mini-politici. Quelli che denunciano gli abusi finiscono per essere testimoni di tristi giochi di potere, interamente svolti sui social media. I movimenti esplodono e implodono, divorati internamente da parassiti. `}</p>
    <p>{`Le città diventano invivibili con l’innalzamento delle acque e i governi si dimenano per mantenere la loro legittimità. Ogni disastro viene sentito come una cosa sempre più intima, sia che ci passi davanti al naso su uno schermo sia che riceviamo il terribile messaggio “hai sentito di…?”. Incidenti paiono massacri. I nomi dei morti, l’indice di una civiltà in declino. Perdiamo la nostra famiglia e i nostri amici alle dipendenze, alla povertà e alla disperazione. Stiamo a guardare la polizia che esercita la sua libertà di uccidere, senza sapere come saziare la nostra rabbia. Ci stringiamo assieme nonostante tutto e rimaniamo in piedi. Sentiamo che siamo stati privati del presente, ci è stato rubato: immaginiamoci allora a quale futuro siamo destinati. Nessuno verrà a salvarci. Dobbiamo dare a noi stessi il terreno su cui crescerà una rivoluzione.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Abbiamo la capacità di attuare una rottura irreversibile.`}</h3>
    <p><em parentName="p">{`Ci svegliamo, giorno dopo giorno,`}</em>{` generazione dopo generazione, andando a lavoro per aiutare a ricalibrare  lo stesso incubo che ci obbliga a lavorare. Ci arrangiamo per tirare avanti, sentiamo lo stress dell’essere pendolari e delle notti insonne, viviamo da una busta paga all’altra o da un lavoretto precario al successivo, tutto solo per non farci staccare l’acqua. È la nostra fatica ad aver costruito questo mondo e a continuare a farlo funzionare, ma nessuno tra noi si sente a casa. Non ci sorprende che così tante persone si lancino in qualsiasi cosa che sembra promettere qualcosa di meglio: movimenti, le tendenze nutrizionali di turno, sottoculture, milizie, gang, qualsiasi cosa.`}</p>
    <p>{`Vogliamo una vita dignitosa. Desideriamo la libertà di poter rivolgere le nostre mani callose alla sperimentazione, di poter essere molto di più del nostro lavoro. Se la potenza dei nostri giorni dà qualche suggerimento, è che siamo capaci di molto più della semplice sopravvivenza. La stessa fatica che cediamo – la nostra forza, creatività e intelligenza – può essere la nostra arma. La possibilità di sopportare è nella nostra capacità di colpire, e nella seduzione del nostro potere condiviso. Il nostro attacco sarà l’immediata riconfigurazione di come viviamo, senza scrupolo per i padroni, per i ricchi, o per i robot intesi a sostituirci. Insieme abbiamo le conoscenze pratiche e l’impulso per costruire una vita migliore, una vita secondo le nostre condizioni, e sta a noi creare e popolare nuovi mondi per sostituire questo. La nostra ingenuità, la nostra passione, la nostra determinazione – siamo il cardine su cui ruota qualsiasi futuro.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Non manca niente. Guardati attorno. Dagli una forma.`}</h3>
    <p><em parentName="p">{`Pezzo per pezzo, stiamo mettendo insieme le fondamenta di una forza rivoluzionaria.`}</em>{` Stiamo costruendo una vita in comune, combattendo la povertà materiale e spirituale che la nostra epoca ci impone, aprendoci all’immediata sperimentazione con diversi modi di vivere. Il nostro obbiettivo è quello di creare territori autonomi – espandendo zone ingovernabili che si estendono da un mare all’altro. Faglie che attraversano il Nord America e ci portano verso il futuro. Questi territori autonomi si apriranno a nuovi flussi di viaggiatori e risorse, come punti di passaggio durante la crisi ecologica, e saranno il terreno dove riappropriarsi di tecniche e tecnologie di cui siamo stati fino ad ora espropriati.`}</p>
    <p>{`Concepiamo il nostro compito con serenità e austerità. Vogliamo territori con una infrastruttura flessibile alla catastrofe, nata dalla gioia collettiva, popolata da una forma di vita coraggiosa e dignitosa. Il nostro tempo è diverso dal passato, e non aspetteremo che ci raggiunga una senile nostalgia da radicali. Non abbiamo tutte le risposta, ma stiamo condividendo quello che sappiamo essere vero. `}</p>
    <p>{`Adesso è il momento di sbarazzarsi di questa forma di vita insostenibile. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      