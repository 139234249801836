import React from 'react'
import {css} from '@emotion/react'
import {useStaticQuery, graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import {colors, media} from 'styles'

const SmallImg = ({index}) => {
  const {images} = useStaticQuery(graphql`
    {
      images: allFile(
        filter: {
          relativePath: {regex: "/instructions/"}
          name: {regex: "/small/"}
          extension: {eq: "jpg"}
        }
      ) {
        edges {
          node {
            childImageSharp {
              id
              gatsbyImageData(width: 212, placeholder: NONE, layout: FIXED)
            }
            relativePath
            name
          }
        }
      }
    }
  `)

  const {
    node: {
      childImageSharp: {gatsbyImageData},
    },
  } = images.edges.find(({node: {name}}) => name.includes(`${index}-small`))
  const inlineOverrides = index === 2 ? {width: 102, height: 212} : {}
  return (
    <GatsbyImage
      image={gatsbyImageData}
      style={inlineOverrides}
      imgStyle={{mixBlendMode: 'multiply'}}
      alt=""
      css={css`
        margin: 2rem auto;
        background-color: ${colors.white};
        ${media(
          900,
          css`
            margin: 2rem 0 6rem;
            margin-right: -15px;
            grid-column: -4 / -3;
            transform: translateY(-30%);
          `
        )};
      `}
    />
  )
}

export default SmallImg
