import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Instructions from '../../../components/instructions';
export const _frontmatter = {
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Instructions;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Instrucciones`}</h2>
    <h3>{`1. Encuéntrense`}</h3>
    <p>{`Hemos sido criadxs en una cultura de aislamiento y derrota, donde nuestro potencial es reducido para cumplir las demandas de la economía. Enterradxs bajo nuestras propias preocupaciones, cuentas y miedos personales, somos forzados a cuidarnos solxs. `}<em parentName="p">{`Pero somos capaces de una vida diferente.`}</em></p>
    <p><em parentName="p">{`Para empezar, eliminen el aislamiento.`}</em>{` Dejen de lado la mierda. Vuélvanse hacia los más cercanos a ustedes y digan que necesitan una vida en común. Pregunten qué significaría enfrentar el mundo juntxs. ¿Qué tienen? ¿Qué necesitan? Hagan inventario de todas sus capacidades, habilidades y conexiones colectivas. Tomen decisiones que incrementen su fuerza. Establezcan la base de una vida en común.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Imaginen una vida que alcance más allá de las fronteras individuales. Pueden cambiar la manera en que se mueven a través de su ambiente para intencionalmente entrar en contacto con otrxs. Encuentros efímeros se vuelven relaciones reales. Se pasean por su vecindario, parando en la casa de sus amigxs en su camino al café. Se encuentran cada noche en el parque para ejercitarse. Se acompañan al ir a casa. Comparten carros. Van a acampar y aprenden cómo empezar un incendio juntos. Juntos ahorran dinero para un dia lluvioso colectivo. El concepto de la propiedad privada se emborrona. Comienzan a entenderse como algo más `}<em parentName="p">{`decisivo`}</em>{` que un grupo de amigxs.`}</p>
    </blockquote>
    <h3>{`2. Establezcan hubs`}</h3>
    <p>{`Los hubs son puntos de agregación, centros de actividad. Crear un hub es el siguiente paso lógico a encontrarse unxs a otrxs. Necesitamos espacios dedicados para organizarnos y darnos un tiempo en conjunto. Los hubs reúnen gente, recursos y el espíritu compartido necesario para sentar los cimientos de una vida en común.`}</p>
    <p><em parentName="p">{`Mancomunen recursos, elijan un área, y empiecen un hub`}</em>{`. Renten un espacio en el vecindario. Construyan infraestructura en el bosque. Aprópiense de un edificio abandonado o un lote vacante de tierra. Ningún espacio es demasiado pequeño o demasiado ambicioso. Empiecen con lo que hay a la mano y luego multipliquen. Usen el hub para anclar todas sus iniciativas.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Una tienda re­adoquinada da lugar a `}<em parentName="p">{`cenas semanales`}</em>{` que se vuelven sesiones de planeamiento. Un café colectivamente administrado ahorra sus ganancias para incubar otros espacios, como un taller de madera donde carpinterxs trabajan juntxs para construir más que solo estantes de libros. En un bosque fuera de la ciudad, un prado sirve como punto de reunión para fogatas semanales y entrenamiento de artes marciales. Cerca, una granja de permacultura se expande lentamente para alimentar a los de la ciudad.`}</p>
    </blockquote>
    <h3>{`3. Vuélvanse resistentes`}</h3>
    <p>{`Nuestros cuerpos nos son un misterio. Nuestra salud está fuera de nuestras manos. Si las luces de apagaran, la mayoría de nosotros seguiría en la oscuridad. Hemos sido despojados de habilidades, pasiones y conocimiento. Pero no somos frágiles. Cuando aprendemos nuevas habilidades o superamos duros obstáculos, nos devolvemos los límites definitivos de nuestro sentido de lo posible. Somos capaces de increíbles e improbables hazañas.`}</p>
    <p><em parentName="p">{`Reclamen habilidades, domínenlas a través de la práctica y compartan su poder.`}</em>{` Conéctense con personas cuyas habilidades les gustaría que todos tuvieran. Usen hubs para experimentar. Prepárense para el nuevo normal. Aprendan a cazar, a escribir código, a sanar: incrementen su fuerza colectiva.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Un huracán arrasa la ciudad – `}<em parentName="p">{`ya no hay luz`}</em>{`. FEMA se toma su tiempo. Un grupo establece un hub afuera de la zona de inundación. Cocinar grandes comidas en conjunto le ha dado a todxs la confianza de operar a escala. Equipos se movilizan para reunir comida en un ambiente sin ley, luchando contra racistas oportunistas que se aferran a un orden de propiedad que ha sido revocado. Alguien junta suministros médicos de los hospitales y farmacias mientras otrx abre tanques de agua en los edificios de departamentos. La ocupación de un parque reúne todavía más gente y recursos. Alguien escala un edificio para poner un router energizado por energía kinética. El router establece una coneccion con una red integrada para llamar refuerzos de otros hubs a lo largo del territorio.`}</p>
    </blockquote>
    <h3>{`4. Compartan un futuro`}</h3>
    <p>{`El tiempo de una vida aislada se ha acabado. Todxs compartimos la catástrofe; todxs compartimos los retos de nuestra época. Podemos protestar la desigual distribución de recursos médicos todo lo que queramos, pero el cuidado de salud solo será universal y digno una vez que sea hecho autónomo.`}</p>
    <p><em parentName="p">{`Inventen formas colectivas de cuidado`}</em>{`. Organícense con los próximos veinte años en mente. Pregúntense cómo sus necesidades cambiarán mientras envejecen, tienen hijxs, se vuelven discapacitados, comienzan a morir. Tomen decisiones basadas en deseos. Imaginen cómo espacios acomodan la naturaleza dinámica de vivir y luchar. Lidien con las preguntas más difíciles: Cómo enfrentar la locura, adicción, violencia interpersonal y pérdida traumática. A cualquier costo, protéjanse unxs a otrxs de la institucionalización.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Una red intergeneracional se forma para lidiar con el todo de vivir. Personas piensan en conjunto sobre cómo criar niñxs, cómo nutrir su agencia, cómo ayudarles a hacerle frente al mundo mientras cambia. El cuidado a los ancianxs es organizado colectivamente y reverencia hacia sus experiencias afirma la dignidad en cada etapa de la vida. Colectivos de salud aprenden métodos anticonceptivos ancestrales para asegurar `}<em parentName="p">{`decisiones autónomas`}</em>{`. Inteligencia emocional compartida ayuda a aquellxs que necesitan un descanso de la lucha y a aquellxs que regresan a ella. Doctores partidarios, herbalistas y chamanes hacen un pacto de proveer cuidado a la red. Todo mundo se siente mejor sabiendo que el hospital no tiene que ser su primera opción. La necesidad de servicios gubernamentales disminuye. Con una nueva orientación hacia la vida y muerte un peso histórico es levantado. Sin las ansiedades y estrés de esta civilización, las enfermedades comienzan a desaparecer. Una nueva capacidad de cuidado se vuelve una reserva de fortaleza comunal para enfrentar el futuro en conjunto.`}</p>
    </blockquote>
    <h3>{`5. A luchar`}</h3>
    <p>{`Nuestra sociedad difama a aquellxs que luchan por lo correcto. Nos dicen que nada puede cambiar, que solxs mejor, y sobre todo, que no peleemos de regreso. Para cultivar un espíritu de lucha en nuestro tiempo, debemos seguir una brújula ética; al igual que desarrollar nuestro pensamiento estratégico y capacidad física.`}</p>
    <p><em parentName="p">{`Fortalézcanse.`}</em>{` Vuélvanse capaces de fuerza. Aprendan el arte de atacar; cómo cualquier cosa puede convertirse en arma. Aprendan a subvertir las fuerzas del enemigo ­ cómo un sólo puñetazo viral puede frenar el ego de fascistas en todos lados, cómo incapacitar al enemigo colectivamente al cortar sus sistema de comunicación.¿Qué obstáculos yacen en el camino hacia una nueva forma de vida? ¿Cómo podemos superarlos en conjunto? ¿Que consideraciones estratégicas nos mantendrán a salvo del enemigo?`}</p>
    <blockquote>
      <p parentName="blockquote">{`Una red de `}<em parentName="p">{`clubes de pelea`}</em>{` conecta a cada gran ciudad. Miembros con experiencia enseñan llaves y golpes así como fitness y estiramiento. Cada club encuentra su espacio y desarrolla lazos con su comunidad, especialmente aquellxs que son desterradxs de este mundo. Una rama en el Norte se moviliza con camioneros para resistir la automatización. Juntos paralizan la carretera con la ayuda de una app de geolocalización, bloquean los camiones automaticos, los contenidos son saqueados. Lo que es útil es expropiado y el resto convertido en cenizas; el humo ciega a las patrullas ya perdidas entre barricadas improvisadas. El cargamento rinde un lote de mini drones, los cuales son activados en patrones de vuelo defensivo, controlados por una sola app reconfigurada. Los drones hackeados se infiltran a los drones policiales entrantes, esparciendo un virus que congela sus propulsores, haciéndoles caer al suelo de manera inofensiva. Actuando entre el caos, los camioneros guerrilleros y lxs del club de pelea toman la ofensiva y hacen su escape.`}</p>
    </blockquote>
    <h3>{`6. Expandan la red`}</h3>
    <p>{`No necesitamos otra organización que nos reúna a hablar de de problemas, sino maneras de implementar prácticas concretas para resolverlos. Necesitamos una red que amplifique el poder de cada proyecto, que expanda el territorio, que se rehuse a dejar el futuro al azar.`}</p>
    <p><em parentName="p">{`Encuéntrese a una escala expandida`}</em>{`. Busquen a otrxs que también estén organizándose. Exploren intensidades nacientes, formas comunales y hagan contacto. Comuníquense, visiten y conózcanse. Intercambien historias y estrategias, de forma que la memoria e inteligencia de nuestra red crezca, construyendo mayor poder entre nosotros. Creen conexiones materiales, compartan o intercambien recursos. Multipliquen este gesto por mil.`}</p>
    <blockquote>
      <p parentName="blockquote">{`En un territorio subversivo, biohackers experimentando con nuevas técnicas hacen innovaciones de `}<em parentName="p">{`purificación de agua`}</em>{`, un grupo de familias indígenas resiste el encercamiento de tierras sagradas por parte de una compañía energética, un hub autónomo redefine su vecindario con varias granjas urbanas. Comunicación regular entre estos tres proyectos lidia con sus necesidades compartidas. Técnicas de trata de agua se esparcen entre ellos mientras la infraestructura autónoma de comida brinda abundancia. La red se convierte en arma cuando las familias indígenas llaman refuerzos para defender sus tierras. Usando comunicación encriptada para coordinar la logística, miles de personas llegan con recursos para ayudar en la lucha.`}</p>
    </blockquote>
    <h3>{`7. Contruyan Autonomía`}</h3>
    <p>{`Nos hemos hecho dependientes de quincenas y tiendas para nuestra existencia básica. Somos dependientes del sistema capitalista que nos fuerza a someternos o morir de hambre. Debemos enfrentar este hecho: la organización material de este mundo es el problema que debemos superar.`}</p>
    <p><em parentName="p">{`Profundicen el alcance de iniciativas de autonomía`}</em>{`. Construyan la infraestructura necesaria para remover territorios de la economía. Resuelvan cuestiones de poder colectivo, material: cómo alimentarnos, proveernos de viviendas, sanarnos. Utilicen data y diseño sin caer en la trampa de que el internet nos salvará. Formen colectivos y cooperativas que logren fines estratégicas sin caer en una economía vacía. Desarrollen soluciones a escala a los problemas de energía, distribución, comunicación y logística.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Un hub local de distribución de comida abre una tienda de abarrotes en el otro lado de la ciudad. Bajo la necesidad de expandirse, la granja cercana que cultiva sus vegetales se integra a una red bioregional buscando compartir un mundo así como comida fresca. Un grupo de diseñadores e ingenieros que odian sus trabajos se juntan para crear una app que coordina una flexible `}<em parentName="p">{`cadena de abastecimiento`}</em>{` entre las granjas y puntos de distribución. Estos esfuerzos llevan a un corredor autónomo de intercambio. El crecimiento de la fuerza de la red, y la completa indiferencia hacia las regulaciones, dejan impotentes a las autoridades, mientras que la comida y la gente circulan libremente con el espíritu de la rebelión.`}</p>
    </blockquote>
    <h3>{`8. Destituyan infraestructura`}</h3>
    <p>{`No queremos mejorar la vida solo para unos pocos selectos – esto es un éxodo masivo de este mundo. Eso significa lidiando con la infraestructura que subyace a esta civilización y readaptando las cosas conforme queramos. Algunos sistemas tendrán que ser desmantelados, como plantas nucleares y de petróleo, mientras que otros pueden ser abiertos a servir la autonomía.`}</p>
    <p><em parentName="p">{`Volteen el sistema.`}</em>{` Vayan desde resolver problemas que la actual infraestructura no puede, a requisar las instituciones existentes y radicalmente cambiar su uso. Ocupen espacios moribundos – palacios de gobierno, escuelas, plazas – llénenlos de nueva vida. Anticipen e intensifiquen fracturas estratégicas. Redireccionen sistemas de comunicaciones. Expropien líneas de abastecimiento. Tomen el poder sin gobernar.`}</p>
    <blockquote>
      <p parentName="blockquote">{`La proliferación de clínicas de salud autónomas comienza a influenciar el mundo de la medicina en todos los frentes. Enfermerxs, doctores y administradores trabajan juntxs para expropiar recursos de hospitales hacia estas clínicas. Cuando los hospitales de los veteranxs ya no cuentan con fondos federales, las clínicas autónomas se unen con pacientes y proveedores de servicios de salud para ocupar las oficinas médicas federales a lo largo del país. Represión brutal en una ocupación manda a docenas a un hospital federal cercano, pero cuando la policía intenta entrar a urgencias para arrestar a lxs veteranxs heridxs, se ven repelidos por lxs cirujanxs y enfermerxs. A Grupos autónomos se les unen fuerzas desbordándose de las ocupaciones y los hospitales, y recursos vitales son expropiados para la insurgencia en marcha.`}</p>
    </blockquote>
    <h3>{`9. Vuélvanse ingobernables`}</h3>
    <p>{`Revolución es una línea que trazamos en el presente.`}</p>
    <p>{`Significa construir autonomía aquí y ahora, haciendo al gobierno y la economía superfluos. Romper la condición de ser gobernadxs significará más que ganar batalla tras batalla, derrotando enemigos políticos. Dependerá de nuestra habilidad de sentar una base duradera para una vida en común.`}</p>
    <p><em parentName="p">{`Esparzan secesión a todas las áreas de la vida.`}</em>{` Hagan huelgas permanentes, lento pero seguro, y lleven a todxs con ustedes. Rehúsense a ser administrados, o a administrar a alguien en turno. Abran una brecha bajo el centro de la sociedad. Repudien una vida de cinismo y resentimiento. Crean que todo es posible.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Huelgas persisten, y el aburrido peso de la deuda se desintegra mientras el capital financiero colapsa bajo creciente hostilidad. Asambleas de vecindario deciden cómo actuar en el estado de emergencia, soldados rebeldes se rehúsan a disparar a sus propios vecindarios, y el “crimen” se ve relegado a las redadas en zonas gubernamentales. En las ciudades, cada dia es como una fiesta callejera. Comidas confiscadas en las calles llenas de gente anuncian un tiempo venidero más allá de estos restos de vida económica, cuando las tiendas estén hechas para un nuevo uso en común. De noche, fogatas iluminan la distancia y las estrellas aparecen en su sabiduría para protegernos. En los suburbios, Un walmart es ahora un hub de organización y bienes. Camioneros y emergencistas se reúnen para coordinar ayuda a un territorio inundado. En el oeste, tecnólogos preparan globos meteorológicos con transmisores para amplificar el internet autónomo. Labor liberado de la economía incrementa el rendimiento de granjas autónomas, y lxs niñxs aprenden de nuevo a ser leales a la tierra.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      