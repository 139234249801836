import React from 'react';
import IntroEs from "../../../../src/content/es/read/_intro.mdx";
import TwoPathsEs from "../../../../src/content/es/read/_two-paths.mdx";
import OurTimeEs from "../../../../src/content/es/read/_our-time.mdx";
import InstructionsEs from "../../../../src/content/es/read/_instructions.mdx";
import NowEs from "../../../../src/content/es/read/_now.mdx";
import ReadEs from "../../../../src/components/read";
export default {
  React,
  IntroEs,
  TwoPathsEs,
  OurTimeEs,
  InstructionsEs,
  NowEs,
  ReadEs
};