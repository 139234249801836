import React from 'react'
import styled from '@emotion/styled'
import {css} from '@emotion/react'
import {colors, type, media, cursors} from 'styles'

const bp = 760

const PathTextWrapper = styled.div`
  margin: 0 30px;

  ${media(
    bp,
    css`
      display: grid;
      grid-template-columns: 30px repeat(6, 1fr) 30px;
      margin: 6rem 0;

      & > * {
        grid-column: 2 / -2;
      }
    `
  )};

  ${media(
    1200,
    css`
      grid-template-columns: 130px repeat(6, 1fr) 130px;
    `
  )};

  & > p {
    font-size: 1.25rem;
    line-height: 1.16;
    color: ${colors.olive};
    ${media(
      bp,
      css`
        grid-column: 2 / -3;
        font-size: 2.8rem;
      `
    )}
  }

  a {
    color: ${colors.orange};
  }

  em {
    font-family: ${type.akzidenz};
    text-transform: uppercase;
    font-style: normal;
    font-size: 0.6em;
    white-space: nowrap;
  }

  em:hover {
    cursor: url(${cursors.pill}), auto;
  }
`

/**
 *
 * @param {children} Array MDX paragraph blocks
 * @param {id} String either 'a' or 'b', used for the image query
 * @returns JSX.Element
 */
export default function PathText({children}) {
  return <PathTextWrapper>{children}</PathTextWrapper>
}
