import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Intro from '../../../components/intro';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Intro;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Zasiedlić`}</h1>
    <p>{`Są dwie`}<br parentName="p"></br>{`
`}{`ścieżki`}</p>
    <p>{`koniec`}<br parentName="p"></br>{`
`}{`świata`}</p>
    <p>{`albo`}</p>
    <p>{`początek`}<br parentName="p"></br>{`
`}{`następnego`}</p>
    <p>{`Musicie wybrać`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      