import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Now from 'components/section-now';
export const _frontmatter = {
  "order": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Now;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Teraz`}</h2>
    <p><em parentName="p">{`Nie ma żadnego przyszłego kryzysu, na który musimy się przygotować.`}</em></p>
    <p>{`Już tu jesteśmy - z każdym elementem dystopii, każdym rewolucyjnym środkiem.
Przerażające konsekwencje naszych czasów i ich piękny potencjał ujawniają
się wszędzie. Opieramy się końcu świata przez namnażanie nowych światów.
Stajemy się nierządne - niepodległe ich bezlitosnemu prawu, ich rozpadającej
się infrastrukturze, ich podłej ekonomii i ich duchowo złamanej kulturze.`}</p>
    <p><em parentName="p">{`Domagamy się szczęścia siłą`}</em>{` - tego, że życie leży w naszej materialnej mocy,
w naszej odmowie bycia rządzonymi, w naszej zdolności do zasiedlenia ziemi,
w naszej trosce wobec siebie nawzajem i w naszych spotkaniach ze wszystkimi
formami życia, które podzielają te etyczne prawdy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      