import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import OurTime from '../../../components/our-time';
import Smudge from '../../../components/smudge';
export const _frontmatter = {
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = OurTime;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nasz czas`}</h2>
    <Smudge mdxType="Smudge" />
    <h3>{`Nasz czas jest burzliwy i potężny.`}</h3>
    <p><em parentName="p">{`Wstrząsy polityczne, polaryzacja, polityka tak zbankrutowana jak rynki finansowe`}</em>{` - a jednak pod kryzysem leżą możliwości. Ta epoka zmusza nas do rozważenia tego, jak każda z nas stanowi ziarnko potencjału, jak jednostki mogą podążyć za swoimi najbardziej szalonymi skłonnościami, by zebrać się z innymi, które czują ten sam zew. Osoby uczą się zapomnianych umiejętności, a wojownicy na powrót niosą światu ogień. Farmerzy i ogrodnicy eksperymentują z organicznym rolnictwem, podczas gdy wytwórcy i hakerzy rekonfigurują maszyny. Modele uciekają od światła scenicznego i łamią chleb z kurdyjskimi radykałami i weteranami wojskowymi, powstającymi w imię wspólnotowego życia. `}</p>
    <p>{`Ci, którym nic po polityce, odnajdują się przy stole obiadowym w parku Zuccotti, na skwerze Oscara Granta czy placu Tahrir. Barista, który ledwo jest w stanie wykarmić siebie, uczy się jak wspólnie z innymi gotować dla tysięcy. Gwiazdka instagramowa, której stany lękowe zwykle więżą ją w mieszkaniu, spotyka w Ferguson zaprawioną w boju osobę - oboje przechodzą przez chrzest gazem pieprzowym i wspólną siłą, i zaczynają czuć lekkość duszy. `}</p>
    <p>{`Ludzie, wszędzie, żyjący w czasach największego wyizolowania, wspólnie powstają i odnajdują nowe tryby życia. Ale kiedy te ziarenka kiełkują, są zadeptywane w szale banalności i strachu. Raz utorowane drogi są siłowo blokowane przez prewencję, prywatną ochronę i firmy pijarowe. Albo nawet gorzej: przez tych samotników - politycznie lewicowych czy prawicowych - którzy nie mają nic do zyskania poza kolejnym lajkiem na ich gównianym Twitterze. W międzyczasie próżni politycy i prezesi firm krążą jak sępy. Nie można zaprzeczyć rewolucyjnemu charakterowi naszej epoki, ale jeszcze nie udało nam się pokonać przeszkód stojących pomiędzy nami a wolnością.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Zrodziłyśmy się z czegoś zepsutego, a jednak stoimy.`}</h3>
    <p><em parentName="p">{`Nihilizm naszej epoki jest topologiczny.`}</em>{` Wszystkiemu brakuje fundamentów. Poszukujemy organizacyjnej siły, by naprawić świat, a znajdujemy jedynie instytucje pełne słabości i cynizmu. Chcących-dobrze aktywistów pożera pozbawione kręgosłupa ciało konwencjonalnej polityki, pozostawiając za sobą albo bojowników z depresją, albo mini-polityków. Ci, którzy otwarcie sprzeciwiają się różnym nadużyciom i przemocy, zostają świadkami smutnych gierek o władzę na social mediach. Ruchy eksplodują, a później implodują, przeżarte od środka przez pasożyty.`}</p>
    <p>{`W miarę jak poziomy oceanów rosną, a rządy usiłują utrzymac swoją legitymizację, miasta stają się niezdatne do życia. Każda katastrofa sprawia poczucie coraz bliższej, niezależnie od tego, czy się przez nią przescrolowujesz, czy dostajesz budzącą strach wiadomość: `}<em parentName="p">{`"słyszałaś?"`}</em>{`. Tracimy członków rodziny i osoby przyjacielskie przez uzależnienia, biedę i rozpacz. Nie mając pojęcia, jak ugasić swoją wściekłość, obserwujemy jak policja korzysta ze swojego prawa do zabijania. Przez cały ten czas trzymamy się siebie i wciąż stoimy. Wyczuwamy teraźniejszość, którą nam skradziono i myślimy o przyszłości, na którą zostałyśmy skazane. Nikt nas nie uratuje. Sami musimy zapewnić sobie grunt, na którym wzrośnie rewolucja.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Mamy siłę, żeby dokonać nieodwracalnego zrywu.`}</h3>
    <p><em parentName="p">{`Budzimy się każdego dnia,`}</em>{` pokolenie po pokoleniu, chodząc do pracy, żeby
zrekalibrować ten sam koszmar, który nas do niej zmusza. Kombinujemy żeby
jakoś przeżyć, stresujemy się dojazdami i nieprzespanymi nocami, i żyjemy
od wypłaty do wypłaty, albo od jednego niepewnego giga do następnego, byleby
mieć wodę w kranie. Choć nasza praca stworzyła ten świat i ona go napędza,
to żadne z nas nie czuje się jak w domu. Nie zaskakuje więc to, że tak wiele
osób wchodzi we wszystko, co obiecuje, że mogłoby być lepiej - ruchy, trendy
zdrowotne, subkultury, milicje, gangi, cokolwiek.`}</p>
    <p>{`Chcemy godnego życia. Pragniemy wolności do eksperymentowania naszymi zrogowaciałymi dłońmi, do stania się kimś więcej, niż tylko naszą pracą. Jeśli pełnia możliwości naszych czasów na cokolwiek wskazuje, to na to, że jesteśmy zdolni do czegoś więcej, niż tylko przeżycia. Sama nasza praca, którą oddajemy - nasza siła, kreatywność i inteligencja - może być naszą bronią. Możliwość wytrwania leży w naszej zdolności do ataku i w uwodzicielskości naszej uwspólnionej siły. Naszym strajkiem będzie natychmiastowa praktyka zrekonfigurowania tego, jak żyjemy, bez szacunku do naszych szefów, bogaczy czy robotów, które mają nas zastąpić. Razem mamy know-how i determinację, by zbudować lepsze życie - życie na swoich własnych warunkach - i by zastąpić ten świat, sami musimy stworzyć i zasiedlić nowe światy. Nasza pomysłowość, pasja i determinacja - to od nas zależy każda przyszłość.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Niczego nam nie brakuje. Spójrzmy dookoła siebie. Nadajmy temu formę.`}</h3>
    <p><em parentName="p">{`Kawałek po kawałku, składamy fundamenty dla rewolucyjnej siły.`}</em>{` Budujemy wspólne życie, walcząc z materialnym i duchowym ubóstwem narzuconym nam  przez naszą epokę i otwierając się na bezpośrednią eksperymentację z różnymi  sposobami życia. Naszym celem jest stworzenie autonomicznych terytoriów  - rozszerzenie nierządnych stref, rozciągających się od morza do morza. Linii  rozłamu przebiegających na wskroś Europy, prowadzących nas ku opatrzności. Te autonomiczne terytoria otworzą się na nowe przepływy dla podróżnych i zasobów, staną się punktami orientacyjnymi podczas kryzysów ekologicznych i gruntem,  na którym będzie można odzyskać techniki i technologie, z których zostałyśmy  wywłaszczone. `}</p>
    <p>{`O naszym zadaniu myślimy ze spokojem ducha i powagą. Chcemy terytoriów z infrastrukturą zdolną dostosować się do katastrofy, zrodzonych z kolektywnej  radości, zasiedlonych przez odważny i godny sposób życia. Nasz czas jest inny od przeszłości i nie będziemy czekać, aż starcza radykalna nostalgia go nadgoni. Nie mamy wszystkich odpowiedzi, ale mamy wspólną prawdę.`}</p>
    <p>{`Czas na opuszczenie tego nienadającego się do niczego trybu życia jest teraz.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      