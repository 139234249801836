import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import OurTime from '../../../components/our-time';
import Smudge from '../../../components/smudge';
export const _frontmatter = {
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = OurTime;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Notre époque`}</h2>
    <Smudge mdxType="Smudge" />
    <h3>{`Notre époque est tumultueuse. Et regorge de potentiel.`}</h3>
    <p><em parentName="p">{`Effervescence, polarisation, la faillite de la politique autant que de la finance`}</em>{` – et pourtant, sous la crise, il y a le possible. L’époque nous force à considérer chacun d'entre nous comme un noyau de potentialité, et à voir comment les individus peuvent suivre leurs penchants les plus fous et se réunir avec ceux qui entendent l'appel. Certains apprennent des savoir-faire perdus, tandis que des guerriers ramènent le feu dans ce monde. Des fermiers et des jardins expérimentent avec l'agriculture biologique pendant que des créateurs et des hackers reconfigurent des machines. Des mannequins échappent au faisceau vide des projecteurs et partagent un repas avec des militants kurdes et des vétérans qui ont pris position pour une vie en commun.`}</p>
    <p>{`Ceux qui n'ont rien à foutre de la politique se retrouvent à table pour diner au Parc Zuccotti, à la  place Tahrir, et la barrista qui arrive à peine à se nourrir seule apprend à cuisiner pour un millier de personnes. Durant l'occupation d'un aéroport, un soudeur retraité et une designer web apprennent qu'ils sont voisins et s'engagent à lire L'art de la guerre ensemble. Une star Instagram normalement confinée dans son appartement par l'anxiété rencontre à Ferguson un aîné marqué par les combats, ils y sont baptisés dans les gaz lacrymogènes et la puissance collective, et ils commencent alors à sentir leur âme soulagée du poids qui leur pesait.`}</p>
    <p>{`Partout, des gens qui vivent dans le plus profond isolement se soulèvent et inventent de nouvelles manières de vivre. Mais quand ces semences poussent et émergent à la surface, elles sont écrasés par la frénésie de la banalité et de la peur. Les ouvertures sont refermées par la force des policiers anti-émeutes, des agences de sécurité privée, et des firmes de relations publiques. Ou pire encore, par des individus isolés – de droite et de gauche – qui n'ont rien de plus à gagner qu'un minable like sur Twitter. Tout cela, alors que des politiciens et des PDGs prétentieux continuent à exister en paix. Le caractère révolutionnaire de notre époque est indéniable, mais ils nous faut encore franchir la barrière qui se dresse entre nous et la liberté.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Nous venons d'un endroit brisé, mais nous tenons debout.`}</h3>
    <p><em parentName="p">{`Le nihilisme de notre époque est topologique.`}</em>{` Tout tient sans fondement. Nous cherchons le pouvoir organisationnel pour réparer le monde, et ne trouvons encore que des institutions lourdes de faiblesse et de cynisme. Les activistes pleins de bonne volonté sont digérés par le corps mou de la politique classique, et il n’en reste que des militants déprimés et des petits politiciens. Celles qui s'expriment contre les abus finissent par n’être témoins que de pitoyables jeux de pouvoir sur les médias sociaux. Les mouvements font éruption, puis ils implosent, dévorés par des parasites internes.`}</p>
    <p>{`La montée des eaux rend les villes invivables et les gouvernements se démènent à garder leur légitimité. Chaque désastre se fait sentir de manière de plus en plus intime, que ce soit ceux qu'on fait défiler sur nos écrans ou qu'on nous annonce dans le texto redouté : `}<em parentName="p">{`as-tu vu?`}</em>{` Les accidents sont ressentis comme des massacres. Les noms des morts deviennent l'index d'une civlisation en déclin. Nous perdons famille et ami·es aux dépendances, à la pauvreté et au désespoir. Nous voyons la police exercer librement son pouvoir d’assassiner, désemparé·es et ne sachant comment étancher notre rage. À travers tout ça, nous nous soutenons, et restons debout. Nous sentons que le présent nous a été volé, nous imaginons le futur qu’on nous prépare. Personne ne viendra nous sauver. Il n'en tient qu'à nous de poser la terre fertile qui saura accueillir une révolution.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Nous pouvons provoquer des ruptures irréversibles`}</h3>
    <p><em parentName="p">{`On se réveille jour après jour,`}</em>{` génération après génération, et on se rend au travail pour recalibrer ce même cauchemar qui nous force à travailler. On s'efforce de joindre les deux bouts, on stresse avec les déplacements au travail et les nuits d'insomnie, on vit de chèque en chèque, de contrat en contrat, juste pour arriver à chauffer nos appartements. Notre travail a construit ce monde et le fait fonctionner, mais personne d'entre nous ne s'y sent chez soi. On ne s’étonne même plus qu'autant de gens jettent leur dévolu sur la première promesse d'une meilleure vie venue – mouvements sociaux, tendances santé, sous-cultures, milis, gangs, peu importe.`}</p>
    <p>{`Nous voulons vivre avec dignité. Nous voulons avoir la liberté d'expérimenter avec nos mains calleuses, d’être bien plus que nos boulots. La force potentielle de notre époque est le signe que nous sommes capables de bien plus que de survivre. Notre labeur – notre force, notre créativité et notre intelligence – , nous pourrions en faire une arme. Notre endurance coïncide avec notre force de frappe et le charme de notre puissance partagée. Notre grève sera une pratique immédiate de transformation des manières de vivre, sans égard à nos patrons, aux riches ou aux robots qui veulent nous remplacer. Ensemble, nous avons les savoir-faire et la volonté pour construire une vie meilleure, une vie sur nos propres termes, et il n'en tient qu'à nous de créer et d'habiter de nouveaux mondes qui remplaceront celui-ci. Notre ingéniosité, notre passion,ore déermination – nous sommes la charnière sur laquelle repose chaque avenir.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Il ne manque rien. Regardez autour de vous. Donnez-y forme.`}</h3>
    <p><em parentName="p">{`Morceau par morceau, nous jetons les bases d'une force révolutionnaire.`}</em>{` Nous construisons une vie en commun, nous combattons la pauvreté matérielle et spirituelle que l'époque nous impose, et nous disposons à l’expérimentation immédiate d'autres modes de vie. Notre objectif consiste à établir des territoires autonomes – assurer l'expansion des zones ingouvernables qui courent d'un océan à l'autre. Les lignes de fracture traversant l'Amérique du Nord sont notre salut. Ces territoires autonomes ouvriront de nouvelles avenues pour faire voyager les gens et les ressources, ce seront de précieux points de repère rebelles lors des crises écologiques, et le terrain à partir duquel récupérer les techniques et les technologies dont nous avons été dépossédés.`}</p>
    <p>{`Nous envisageons notre tâche avec sérénité et sévérité. Nous voulons des territoires avec des infrastructures flexibles en temps de catastrophe, nées d'une joie collective, habitées par des êtres qui vivent avec dignité et courage. Notre temps n’est plus celui du passé – et nous ne voulons en aucun cas être rattrapé·es par une nostalgie aussi radicale que sénile. Nous n'avons certes pas toutes les réponses, mais nous offrons en partage ce que nous savons être vrai. `}</p>
    <p>{`Il est maintenant temps de quitter ce mode de vie insupportable.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      