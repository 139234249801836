import React from 'react'
import BigImg from './instruction-big-img'
import SmallImg from './instruction-small-img'
import {Wrapper, Heading, Body, Vignette} from './instruction-components'
import MomentImg from 'components/moment-img'
import {getSlug} from 'utils'
import {useState} from 'react'

const Instruction = ({
  p,
  link,
  blockquote,
  index,
  h3: {
    props: {children: h3},
  },
}) => {
  const [hovering, setIsHovering] = useState(false)
  const headingParts = {
    title: h3.substring(h3.indexOf(' ') + 1, h3.length),
    index,
  }

  return (
    <Wrapper id={getSlug(h3)}>
      <Heading {...headingParts} />
      <BigImg index={index} />
      <Body>{p}</Body>

      <SmallImg index={index} />

      <Vignette hovering={hovering} setIsHovering={setIsHovering}>
        {blockquote}
        <MomentImg index={index} show={hovering} />
      </Vignette>
    </Wrapper>
  )
}

export default Instruction
