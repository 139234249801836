import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Instructions from '../../../components/instructions';
export const _frontmatter = {
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Instructions;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Instrukcje`}</h2>
    <h3>{`1. Odnajdźmy się`}</h3>
    <p>{`Zostałyśmy wychowane w kulturze izolacji i pokonania, w której nasz potencjał jest redukowany do spełniania wymagań gospodarki. Zakopane pod swoimi osobistymi zmartwieniami, swoimi rachunkami i swoimi lękami, jesteśmy zmuszone, żeby dbać wyłącznie o siebie. `}<em parentName="p">{`Ale jesteśmy zdolne do innego życia.`}</em></p>
    <p><em parentName="p">{`Zacznij od wyeliminowania izolacji.`}</em>{` Przebij się przez całe to pierdolenie, zwróć się do swoich najbliższych i powiedz, że potrzebujesz wspólnego życia. Jakby to było, gdybyście razem stawiły czoła światu? Co macie? Czego potrzebujecie? Spiszcie wszystkie swoje kolektywne umiejętności, zdolności i powiązania. Podejmujcie decyzje, które zwiększają waszą siłę. Stwórzcie bazę dla wspólnego życia.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Wyobraźcie sobie życie, które przekracza wasze indywidualne granice. Zmieniacie to, jak poruszacie się w swoim środowisku, by celowo wejść w kontakt z innymi. Przelotne spotkania stają się prawdziwymi relacjami. Szwędacie się po swojej okolicy, zatrzymując się w domach swoich przyjaciół po drodze do kawiarni. Spotykacie się wieczorem w parku, żeby ćwiczyć. Odprowadzacie się wzajemnie do domów. Wspólnie używacie swoich samochodów. Robicie sobie biwak i razem uczycie się, jak rozpalić ogień. Zbieracie pieniądze na kolektywną czarną godzinę. Idea prywatnej własności staje się rozmyta. Zaczynacie rozumieć siebie jako coś bardziej `}<em parentName="p">{`decydującego`}</em>{` od zwykłej grupki przyjaciół.`}</p>
    </blockquote>
    <h3>{`2. Załóżmy ośrodki`}</h3>
    <p>{`Ośrodki są punktami skupienia, centrami aktywności. Stworzenie ośrodka jest następnym logicznym krokiem w odnajdywaniu się. Potrzebujemy dedykowanych przestrzeni do organizacji i zapewnienia sobie wspólnego czasu. Ośrodki łączą ludzi, zasoby i wspólnego ducha potrzebnego do stworzenia fundamentów dla wspólnego życia.`}</p>
    <p><em parentName="p">{`Zgromadźcie zasoby, wybierzcie obszar i załóżcie ośrodek.`}</em>{` Wynajmijcie przestrzeń w okolicy. Zbudujcie struktury w lesie. Przejmijcie opuszczony budynek albo pusty kawałek ziemi. Żadna przestrzeń nie jest za mała, albo zbyt ambitna. Zacznijcie od tego, co pod ręką, a potem się pomnóżcie. Użyjcie ośrodka do ugruntowania wszystkich swoich inicjatyw.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Były sklep gości teraz `}<em parentName="p">{`cotygodniowe obiady`}</em>{`, które zamieniają się w sesje planowania. Kolektywnie prowadzona kawiarnia odkłada pieniądze, żeby pomóc rozwinąć się innym przestrzeniom - jak warsztat, w którym stolarze budują znacznie więcej niż tylko półki na książki. Polana w podmiejskim lesie służy za miejsce gromadzenia się na cotygodniowe ogniska i treningi sztuk walki. Nieopodal, mała permakulturowa farma powoli się rozbudowuje, by karmić tych, którzy żyją w mieście.`}</p>
    </blockquote>
    <h3>{`3. Stańmy się wytrzymałe`}</h3>
    <p>{`Nasze ciała są dla nas zagadką. Nasze zdrowie leży poza naszą kontrolą. Jeśli zgasłyby światła, większość z nas pozostałoby w ciemności. Zostałyśmy wywłaszczone z umiejętności, pasji i wiedzy. Ale nie jesteśmy wątłe. Kiedy uczymy sie nowych umiejętności i przezwyciężamy srogie trudności, szablą odbieramy nasze poczucie tego, co jesteśmy w stanie zrobić. Jesteśmy zdolne do niesamowitych i nieprawdopodobnych wyczynów.`}</p>
    <p><em parentName="p">{`Odzyskajmy umiejętności, udoskonalmy je na drodze praktyki i podzielmy się płynącą z nich siłą.`}</em>{` Odezwijcie się do ludzi mających zdolności, które chciałybyście, by mieli wszyscy. Użyjcie ośrodków do eksperymentacji. Przygotujcie się na nową normalność. Nauczcie się polować, programować, leczyć: zwiększcie swoją kolektywną siłę.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Powódź zalewa miasto, nie ma prądu, a RCB się nie spieszy. Grupa zakłada ośrodek poza obszarem zalewowym. Wcześniejsze wspólne gotowanie dużych obiadów dało wszystkim pewność siebie w operowaniu na dużej skali. Różne zespoły wyruszają, by zebrać jedzenie w strefie bezprawia, walcząc z rasistowskimi oportunistami, którzy obstają przy unieważnionym porządku własności. Jeden zbiera zapasy medyczne ze szpitali i aptek, podczas gdy inny otwiera zbiorniki z wodą. Okupacja parku łączy jeszcze więcej ludzi i zasobów. Ktoś wspina się na budynek, żeby zainstalować router zasilany energią kinetyczną. Dzięki niemu możliwe staje się połączenie z resztą sieci mesh, żeby wezwać posiłki z innych ośrodków rozsianych po terytorium.`}</p>
    </blockquote>
    <h3>{`4. Współdzielmy przyszłość`}</h3>
    <p>{`Czas samotnego życia się skończył. Wszystkie podzielamy tę katastrofę i wyzwania, które stawia przed nami nasza epoka. Możemy protestować przeciwko nierównej dystrybucji medycznych zasobów tyle, ile chcemy, ale troska stanie się uniwersalna i godna tylko wtedy, gdy uczyni się ją autonomiczną.`}</p>
    <p><em parentName="p">{`Stwórzcie kolektywne formy troski.`}</em>{` Zorganizujcie się z myślą o następnych dwudziestu latach. Porozmawiajcie o tym, jak zmienią się wasze potrzeby wraz z tym, jak będziecie się starzeć, mieć dzieci, tracić sprawność czy zaczynać umierać. Podejmujcie decyzje w oparciu o pragnienie. Wyobraźcie sobie, jak w różnych przestrzeniach pomieścić dynamiczną naturę życia i walki. Odpowiedzcie sobie na najtrudniejsze pytania: jak poradzić sobie z szaleństwem, uzależnieniem, przemocą interpersonalną i traumatyczną stratą. Za wszelką cenę ochrońcie siebie nawzajem przed instytucjonalizacją.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Formuje się międzypokoleniowa sieć, by zaadresować całość życia. Ludzie wspólnie zastanawiają się, jak wychować dzieci, jak wspierać ich podmiotowość i jak pomóc im radzić sobie ze zmieniającym się światem. Kolektywnie zorganizowana jest troska nad starzejącymi się, a szacunek dla ich doświadczeń afirmuje godność na każdym etapie życia. Kolektywy zdrowotne uczą się metod antykoncepcji i aborcji swoich przodkiń, by zapewnić `}<em parentName="p">{`autonomiczny wybór`}</em>{`. Wspólna inteligencja emocjonalna wspomaga tych, którzy potrzebują przerwy od walki i pomaga tym, którzy do niej powracają. Partyzanccy doktorzy, zielarki i szamani zawierają pakt, by zapewnić sieci opiekę. Wszyscy są spokojniejsi wiedząc, że w razie czego, szpital nie musi być ich pierwszą opcją. Jest coraz mniej osób potrzebujących rządowych usług. Dzięki nowemu podejściu do życia i śmierci, zrzucony zostaje historyczny ciężar. Bez lęków i stresu tej cywilizacji, choroba zaczyna zanikać. Nowa zdolność do dawania troski staje się wspólnym rezerwuarem siły do tego, by razem stawić czoła przyszłości.`}</p>
    </blockquote>
    <h3>{`5. Podejmijmy walkę`}</h3>
    <p>{`Nasze społeczeństwo szarga dobre imię osób, które walczą o to, co słuszne. Powtarza się nam, że nic się nie zmieni, żebyśmy trzymały się na uboczu i przede wszystkim, nie sprzeciwiały się. Żeby w dzisiejszych czasach pielęgnować ducha walki, to poza rozwinięciem myśli strategicznej i budowaniem fizycznej sprawności musimy podążać też za kompasem etycznym.`}</p>
    <p><em parentName="p">{`Stańcie się silniejsze`}</em>{`. Uczyńcie się zdolnymi do stosowania siły. Nauczcie się sztuki uderzania, tego, jak wszystko może stać się bronią. Nauczcie się podkopać siłę wroga - przejdźcie od tego, jak viralowe staje się uderzenie faszysty, do tego, jak kolektywnie unieszkodliwić wroga przez odcięcie jego systemów komunikacyjnych. Jakie przeszkody stoją na naszej drodze do nowego sposobu życia? Jak możemy je pokonać, razem? Co musimy strategicznie rozważyć, by nie wpaść w ręce wroga?`}</p>
    <blockquote>
      <p parentName="blockquote">{`Sieć `}<em parentName="p">{`klubów walki`}</em>{` łączy każde większe miasto. Doświadczeni członkowie uczą chwytów, uderzeń, rozciągania i prowadzą podstawowe treningi sprawnościowe. Każdy klub tworzy własną przestrzeń i buduje więzi ze swoją społecznością, szczególnie tymi, którzy są odrzucani przez ten świat. Jeden z węzłów na zachodzie mobilizuje TIRowców, żeby stawili opór automatyzacji. Razem z nimi paraliżują A4 z pomocą apki geolokalizacyjnej, blokują samojezdne ciężarówki i włamują się do ich przyczep. To, co przydatne, jest wywłaszczone, a reszta idzie z dymem. Dym dodatkowo zaślepia radiowozy, pogubione już pośród prowizorycznych barykad. Ładunek dostarcza partii mini-dronów, które wysłane są według defensywnych schematów lotu poprzez zrekonfugurowaną apkę. Zhackowane drony infiltrują nadlatujące drony policyjne, żeby przekazać wirusa, który unieruchamia ich śmigła i, unieszkodliwione, strąca na ziemię. Korzystając z chaosu, wojujący TIRowcy i członkowie klubu przechodzą do ofensywy i torują sobie drogę ucieczki.`}</p>
    </blockquote>
    <h3>{`6. Rozszerzmy sieć`}</h3>
    <p>{`Nie potrzebujemy kolejnej organizacji, która znów nas zbierze byśmy porozmawiali o problemach - potrzebujemy raczej sposobów na implementację konkretnych praktyk, które je rozwiążą. Potrzebujemy sieci, która wzmocni siłę każdego projektu, rozszerzy terytorium i nie zgodzi się zostawienie przyszłości przypadkowi.`}</p>
    <p><em parentName="p">{`Odnajdźcie się na większej skali`}</em>{`. Szukajcie innych ludzi, którzy też się organizują. Wytropcie rodzące się intensywności i formy komunalne i nawiążcie kontakt. Wyjdźcie osobom naprzeciw, stwórzcie linie komunikacji, odwiedźcie się i spotkajcie. Wymieńcie się opowieściami i strategiami, tak by kulturowa pamięć naszej sieci i inteligencja operacyjna rosła, budując wzajemną siłę. Stwórzcie materialne powiązania, podzielcie się albo wymieńcie zasobami. I zwielokrotnijcie ten gest tysiąckrotnie.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Na jednym wywrotowym terytorium, eksperymentujący z nowymi technikami biohackerzy czynią postępy w `}<em parentName="p">{`oczyszczaniu wody`}</em>{`, grupa rdzennych rodzin stawia opór grodzeniu ich świętej ziemi przez firmę energetyczną, a autonomiczny ośrodek redefiniuje okolicę patchworkiem miejskim farm. Regularna komunikacja pomiędzy tymi trzema projektami adresuje ich wspólne potrzeby. Szerzą pomiędzy sobą metody obróbki wody, podczas gdy autonomiczna infrastruktura żywieniowa prowadzi do dostatku. Kiedy rdzenne rodziny wzywają po posiłki do obrony swojej ziemi, z sieci robi się zbrojny użytek. Używając zaszyfrowanej komunikacji do koordynacji logistycznej, przybywają tysiące osób z zasobami do wsparcia rodzącej się walki.`}</p>
    </blockquote>
    <h3>{`7. Zbudujmy autonomię`}</h3>
    <p>{`W kwestii podstawowej egzystencji zmuszono nas do polegania na wypłatach i sklepach. Jesteśmy zależne od kapitalistycznego systemu, który zmusza nas, byśmy albo się podporządkowały, albo umarły z głodu. Materialna organizacja obecnego świata jest problemem, który musimy przezwyciężyć. Nie da się tego obejść.`}</p>
    <p><em parentName="p">{`Pogłębcie zakres autonomicznych inicjatyw.`}</em>{` Zbudujcie infrastrukturę potrzebną do wyjęcia terytorium spod władzy gospodarki. Odpowiedzcie na pytania kolektywnej, materialnej władzy: jak się nakarmić, dać sobie dach nad głową, uleczyć się. Wykorzystajcie dane i design bez wpadania w pułapkę myślenia, że internet nas uratuje. Uformujcie kolektywy i kooperatywy, które osiągną strategiczne cele bez wkupywania się w bezsensowną gospodarkę. Rozwińcie skalowalne rozwiązania problemów energii, dystrybucji, komunikacji i logistyki.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Lokalny, dystrybuujący jedzenie ośrodek otwiera kooperatywny warzywniak po drugiej stronie miasta. Dostarczająca im warzyw pobliska farma, potrzebując rozwinąć swoje możliwości, integruje się w bioregionalną sieć, chcącą dzielić z innymi tak świeże pożywienie, jak i świat. Grupa nienawidzących swojej pracy designerów i inżynierów zbija się, żeby stworzyć apkę, która koordynuje elastyczny `}<em parentName="p">{`łańcuch dostaw`}</em>{` pomiędzy farmami i punktami dystrybucyjnymi. Dzięki tym wysiłkom, autonomiczne korytarze handlowe wyrastają jak grzyby po deszczu. Wzrost siły sieci i kompletny brak szacunku dla regulacji pozostawia władze bezradnymi, a pożywienie, jak i ludzie, krąży swobodnie wraz z duchem buntu.`}</p>
    </blockquote>
    <h3>{`8. Zdestytuujmy infrastrukturę`}</h3>
    <p>{`Nie chcemy poprawić życia dla zaledwie kilku wybrańców - to masowy eksodus z tego świata. To oznacza zaadresowanie problemu infrastruktury, którą ta cywilizacja stoi, i swobodne używanie rzeczy niezgodnie z ich przeznaczeniem. Niektóre systemy będzie trzeba zdemontować, jak rurociągi naftowe i elektrownie atomowe, a inne będzie można rozmontować, żeby mogły służyć autonomii.`}</p>
    <p><em parentName="p">{`Zhackujcie wszystko`}</em>{`. Przejdźcie od rozwiązania problemów, których obecna infrastruktura nie może zaadresować, do przejęcia istniejących instytucji i radykalnej zmiany ich użytku. Zajmijcie nudne przestrzenie - ratusze, szkoły czy galerie handlowe - i tchnijcie w nie nowe życie. Przewidźcie i zintensyfikujcie strategiczne rozłamy. Przekierujcie systemy komunikacyjne. Zarekwirujcie łańcuchy dostaw. Przejmijcie władzę nie rządząc.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Namnażanie się autonomicznych klinik zaczyna mieć swój wpływ na świat medycyny na wszystkich frontach. Pielęgniarze, lekarki i administratorzy pracują razem, by potajemnie pompować w te kliniki szpitalne zasoby. Kiedy wojskowe szpitale przestają być finansowane, autonomiczne kliniki dołączają do pacjentów i urzędników, by okupować biura MONu w całym kraju. Brutalne represje na jednej okupacji wysyłają wielu do państwowego szpitala nieopodal, ale kiedy policja próbuje wejść na SOR, by aresztować rannych weteranów, zostaje odepchnięta przez przyjaznych im chirurgów i pielęgniarzy. Do autonomicznych grup dołączają siły wylewające się z tych okupacji i wszyscy przejmują szpital, wraz z zasobami niezbędnymi dla rozwijającego się powstania.`}</p>
    </blockquote>
    <h3>{`9. Stańmy się nierządne`}</h3>
    <p>{`Rewolucja jest linią, którą wytyczamy w teraźniejszości.`}</p>
    <p>{`Oznacza to budowanie autonomii tu i teraz, sprawiając, że rząd i gospodarka stają się zbędne. Wyrwanie się z bycia rządzonymi będzie oznaczać więcej niż wygrywanie bitwy za bitwą i wymanewrowywanie politycznych wrogów. Spocznie na naszej umiejętności stworzenia trwałego fundamentu pod wspólne życie.`}</p>
    <p><em parentName="p">{`Rozprzestrzeńcie secesję na wszystkie obszary życia`}</em>{`. Podejmijcie permanentny strajk i zabierzcie wszystkich ze sobą. Odmówcie bycia zarządzanymi i zarządzania innymi. Wbijcie klin w sam środek społeczeństwa. Wyprzyjcie się życiowego cynizmu i niechęci. Uwierzcie, że to wszystko jest możliwe.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Strajki nie ustępują, a tępy ciężar zadłużenia dezintegruje, w miarę jak kapitał finansowy doznaje zapaści pod wpływem rosnącej wrogości. Zgromadzenia sąsiedzkie decydują jak działać w stanie wyjątkowym, buntujący się żołnierze odmawiają ostrzeliwania własnych sąsiedztw, a "przestępczość" ogranicza się teraz do najazdów na strefy rządzone. W miastach, każdy dzień jest jak impreza na blokach. Wspólne gotowanie ze skonfiskowanych składników na zatłoczonych ulicach zwiastuje czas wykraczający poza pozostałości ekonomicznego życia - sklepy są przygotowane do nowego wspólnego użytku. W nocy, ogniska lśnią na horyzoncie, a gwiazdy, w swojej mądrości, powracają by nas ochronić. Na przedmieściach, Selgros jest teraz ośrodkiem darmowych przedmiotów i organizowania się. TIRowcy i ratownicy medyczni spotykają się, żeby skoordynować pomoc dla zalanego terenu. Na zachodzie, technologiści wyposażają balony pogodowe w przekaźniki, żeby wzmocnić sygnał autonomicznego internetu. Praca wolna od ekonomii zwiększa plony autonomicznych farm, a dzieci na nowo uczą się, jak być lojalne wobec ziemi.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      