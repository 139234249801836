import React, {useRef, useState} from 'react' // eslint-disable-line
import styled from '@emotion/styled'
import {css} from '@emotion/react'
import {type, colors, media, cursors} from 'styles/index'
import video from 'assets/video/our-time.mp4'
import poster from 'assets/images/our-time-poster.jpg'

const OurTime = ({children, className}) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(true)
  return (
    <div className={className}>
      <video
        autoPlay={process.env.NODE_ENV !== 'development'}
        poster={poster}
        loop
        muted={true}
        ref={videoRef}
        playsInline
        onClick={() => {
          if (isPlaying) {
            videoRef.current.pause()
            setIsPlaying(false)
          } else {
            videoRef.current.play()
            setIsPlaying(true)
          }
        }}
        css={css`
          grid-column: 2 / -1;
          margin-left: auto;
          width: 90%;
          cursor: pointer;
        `}
      >
        <source src={video} type="video/mp4" />
      </video>
      {children}
    </div>
  )
}

const StyledOurTime = styled(OurTime)`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 40px auto 40px;
  & > * {
    grid-column: 2 / -2;
  }

  ${media(
    900,
    css`
      margin: 0;
      grid-template-columns: 60px repeat(12, 1fr) 60px;
      grid-column-gap: 15px;
    `
  )}

  h3 {
    position: relative;
    font-family: ${type.lydia};
    color: ${colors.orange};
    line-height: 1;
    font-size: 3rem;
    margin: 6rem 0;

    ${media(
      900,
      css`
        font-size: 4.5rem;
        grid-column: 2 / 8;
      `
    )};

    & + p {
      ${media(
        900,
        css`
          grid-column: 3 / span 4;
          grid-row: span 3;
        `
      )};
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: ${colors.olive};

    em {
      font-family: ${type.pmGrotesk};
      font-style: normal;
      font-weight: normal;
    }

    em:hover {
      cursor: url(${cursors.pill}), auto;
    }

    ${media(
      900,
      css`
        grid-column: 8 / span 4;
        grid-row: span 1;
      `
    )};
  }
`

export default StyledOurTime
