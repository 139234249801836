import React from 'react'
import {css} from '@emotion/react'
import {useStaticQuery, graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import VisuallyHidden from '@reach/visually-hidden'
import {
  IntroWrapper,
  BackgroundWrapper,
  InhabitLogo,
  Choose,
  YouHaveTo,
} from './intro-components'
import {useContext} from 'react'
import {LanguageContext} from 'context/language-context'

import englishLogo from 'assets/images/inhabit-en-lg.svg'
import spanishLogo from 'assets/images/inhabit-es-lg.svg'
import frenchLogo from 'assets/images/inhabit-fr-lg.svg'

const logos = {
  en: englishLogo,
  pl: englishLogo,
  it: englishLogo,
  es: spanishLogo,
  fr: frenchLogo,
}
/**
 * Reorganize MDX Children so that they
 * are easier to style
 */
const reduceChildren = (children) => {
  return children.reduce((content, child) => {
    // pull h1 out into it's own property
    if (child.props.mdxType === 'h1') {
      content.h1 = child
      return content
    }

    // drop the rest in as an array
    if (!Array.isArray(content.choose)) {
      content.choose = []
    }
    content.choose.push(child)
    return content
  }, {})
}

const Intro = ({children}) => {
  const {lang} = useContext(LanguageContext)
  const {h1, choose} = reduceChildren(children)
  const {background} = useStaticQuery(graphql`
    {
      background: file(relativePath: {eq: "crow.jpg"}) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <>
      <IntroWrapper>
        <InhabitLogo>
          <VisuallyHidden>{h1}</VisuallyHidden>
          <img src={logos[lang]} alt="" hidden />
        </InhabitLogo>
        <Choose>
          {choose.map((child, i) => {
            if (i < choose.length - 1) {
              return child
            }
            return <YouHaveTo key={i}>{child}</YouHaveTo>
          })}
        </Choose>
      </IntroWrapper>
      <BackgroundWrapper>
        <GatsbyImage
          image={background.childImageSharp.gatsbyImageData}
          loading="eager"
          style={{position: 'fixed'}}
          alt=""
          css={css`
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
          `}
        />
      </BackgroundWrapper>
    </>
  )
}

export default Intro
