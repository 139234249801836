import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import OurTime from '../../../components/our-time';
import Smudge from '../../../components/smudge';
export const _frontmatter = {
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = OurTime;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Our Time`}</h2>
    <Smudge mdxType="Smudge" />
    <h3>{`Our Time Is Tumultuous And Potent.`}</h3>
    <p><em parentName="p">{`Upheaval, polarization, politics as bankrupt as the financial markets`}</em>{`—yet under crisis lies possibility. This epoch forces us to consider how each of us forms a kernel of potential, how individuals can follow their wildest inclinations to gather with others who feel the call. People learn lost skills and warriors return fire to the world. Farmers and gardeners experiment with organic agriculture while makers and hackers reconfigure machines. Models escape the vacant limelight and break bread with Kurdish radicals and military veterans taking a stand for communal life.`}</p>
    <p>{`Those with no use for politics find each other at a dinner table in Zuccotti Park, Oscar Grant Plaza, or Tahrir Square, and the barista who can barely feed himself alone learns to cook for a thousand together. An Instagram star whose anxiety usually confines them to their apartment meets a battle-scarred elder in Ferguson, where they are baptized in tear gas and collective strength, and begin to feel the weight lifted from their soul.`}</p>
    <p>{`People everywhere, living through the greatest isolation, rise together and find new modes of life. But when these kernels grow to the surface, they are stomped out in a frenzy of banality and fear. Openings are forcefully shuttered by riot police, private security forces, and public relations firms. Or worse, by the lonely ones—politically right or left—who have nothing to gain but another Like on their crappy Twitter. All this while smug politicians and CEOs hover. The revolutionary character of our epoch cannot be denied, but we've yet to overcome the hurdle between us and freedom.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`We Come From Somewhere Broken, Yet We Stand.`}</h3>
    <p><em parentName="p">{`Our epoch's nihilism is topological.`}</em>{` Everywhere is without foundation.
We search for the organizational power to repair the world and find only
institutions full of weakness and cynicism. Well-meaning activists get
digested through the spineless body of conventional politics, leaving
depressed militants or mini-politicians. Those who speak out against abuse
end up bearing witness to sad games of power playing out on social media.
Movements erupt and then implode, devoured internally by parasites.`}</p>
    <p>{`Cities become unlivable as waters rise and governments scramble to maintain
their legitimacy. Each disaster feels more and more intimate, whether we
scroll through it or receive the dreaded text `}<em parentName="p">{`did you hear?`}</em>{` Accidents feel
like massacres. The names of the dead, an index of a civilization in
decline. We lose family and friends to addiction, poverty, and despair.
We watch the police exercise their freedom to murder, at a loss for how
to quench our rage. We hold each other through it all and remain standing.
We sense the present that has been stolen from us, imagine the future we
are fated. No one is coming to save us. We have to give ourselves the
ground on which a revolution will grow.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`We Have The Power To Make An Irreversible Break.`}</h3>
    <p><em parentName="p">{`We wake up day after day,`}</em>{` generation after generation, going to work in
order to recalibrate the same nightmare that forces us to work. We hustle
to get by, feel the stress of the commute and the sleepless night, live
paycheck to paycheck or one precarious gig to the next, all just to keep
the water on. Our labor made this world and keeps it running, but not
one of us feels at home. It's not surprising that so many people throw
themselves into anything that promises it could be better— movements,
health trends, subcultures, militias, gangs, whatever.`}</p>
    <p>{`We want a dignified life. We desire the freedom to turn our calloused
hands to experimentation, to become so much more than our jobs. If the
potency of our time is any indication, it's that we're capable of more
than mere survival. The very labor we give—our strength, creativity,
and intelligence—can be our weapon. The possibility to endure is in
our capacity to strike, and in the seduction of our shared power. Our
strike will be the immediate practice of reconfiguring how we live,
without respect to our bosses, the rich, or the robots intended to
replace us. Together we have the know- how and the drive to build a
better life, a life on our own terms, and it’s up to us to create and
inhabit new worlds to replace this one. Our ingenuity, our passion,
our determination—we are the hinge on which every future rests.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Nothing Is Missing. Look Around You. Give It Form.`}</h3>
    <p><em parentName="p">{`Piece by piece, we are assembling the foundation of a revolutionary force.`}</em>{`
We are building a life in common, combating the material and spiritual
poverty imposed on us by our epoch, and opening ourselves up to immediate
experimentation with different ways of living. Our goal is to establish
autonomous territories—expanding ungovernable zones that run from sea to
shining sea. Faultlines crossing North America leading us to providence.
These autonomous territories will open to new flows for travel and resources,
waypoints during ecological crisis, and the ground to reclaim techniques and
technologies of which we've been dispossessed.`}</p>
    <p>{`We envision our task with serenity and severity. We want territories with
infrastructure flexible to catastrophe, born of collective joy, inhabited
by a courageous and dignified way of life. Our time is different from the
past, and we will not wait for a senile radical nostalgia to catch up. We
don’t have every answer, but we share what we know to be true.`}</p>
    <p>{`Now is the time to exit this untenable way of life.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      