import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Path from '../../../components/two-paths/path-text';
import PathHero from '../../../components/two-paths/path-hero';
import PathImg from '../../../components/two-paths/path-img';
import Marquee from '../../../components/marquee';
export const _frontmatter = {
  "order": 0
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Two Paths`}</h2>
    <h2>{`Two Paths`}</h2>
    <Marquee mdxType="Marquee">Koniec świata</Marquee>
    <PathHero id="a" mdxType="PathHero">
      <h3>{`Ścieżka A`}</h3>
      <p>{`To koniec.`}<br parentName="p"></br>{`
`}{`Zegnijcie karki`}</p>
      <p>{`i`}</p>
      <p>{`przescrolujcie`}<br parentName="p"></br>{`
`}{`przez apokalipsę`}</p>
    </PathHero>
    <Path mdxType="Path">
      <p>{`Obserwujcie, jak Dolina Krzemowa zastępuje wszystko robotami.
Nowe fundamentalistyczne kulty śmierci sprawiają, że
ISIS w porównaniu z nimi wygląda jak dziecinna igraszka.
Władze wypuszczają apkę geolokalizacyjną umożliwiającą donoszenie
na imigrantów i wywrotowców w czasie rzeczywistym, podczas gdy
metafaszyści crowdfundują następne obozy koncentrancyjne.
Rządowe usługi stają się niewydolne. Politycy sięgają po coraz
to bardziej drakońskie środki, a lewica jest jak pies, który dużo
szczeka, ale nie gryzie.`}</p>
      <PathImg id="a" mdxType="PathImg" />
      <p>{`W międzyczasie lodowce topnieją, lasy płoną, huragan jakiśtam
zatapia kolejne miasto. Starożytne plagi wyłaniają się spod
roztapiającej się wiecznej zmarzliny. Niekończąca się praca
na rzecz bogaczy, którzy korzystają na ruinie. W końcu, ze
świadomością własnej bierności, `}<em parentName="p">{`giniemy`}</em>{`, kończąc w zbiorowym
grobie z całą resztą żywych istot tej planety.`}</p>
    </Path>
    <Marquee mdxType="Marquee">Początek następnego</Marquee>
    <PathHero id="b" mdxType="PathHero">
      <h3>{`Ścieżka B`}</h3>
      <p>{`Weź oddech`}</p>
      <p>{`i`}</p>
      <p>{`przygotuj się`}<br parentName="p"></br>{`
`}{`na nowy świat.`}</p>
    </PathHero>
    <Path mdxType="Path">
      <p>{`Wielość osób, przestrzeni i infrastruktur wyrasta tam, gdzie kształtów 
nabierają potężne, autonomiczne terytoria. Wszystko jest dla wszystkich.
Ziemię oddaje się na powszechny użytek. Technologia pokazuje swoje
prawdziwe oblicze - wszystko jest narzędziem, wszystko jest bronią.
Autonomiczne linie zaopatrzeniowe pozwalają zrzucić jarzmo gospodarki.
Sieci mesh umożliwiają komunikację w czasie rzeczywistym, łącząc tych,
którzy czują, że trzeba zbudować inne życie.`}</p>
      <PathImg id="b" mdxType="PathImg" />
      <p>{`Podczas gdy rządy upadają, autonomiczne terytoria rosną wraz z nowym poczuciem,
że by być wolnym, musimy być związani z tą ziemią i życiem na niej. Enklawy
techno-feudalizmu są plądrowane, żeby zdobyć zasoby. Konfrontujemy kurczące
się siły kontrrewolucji z wyborem: `}<em parentName="p">{`do piekła, czy utopii?`}</em></p>
      <p>{`Nas zadowalają obie odpowiedzi.`}</p>
      <p>{`W końcu, docieramy na skraj - czujemy niebezpieczeństwo wolności, objęcia bycia
razem, to, co niezwykłe i nieznane - i już wiemy: to jest życie.`}</p>
    </Path>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      