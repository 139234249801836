import React from 'react'
import {css} from '@emotion/react'
import {useStaticQuery, graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import {media} from 'styles'

const BigImg = ({index}) => {
  const {images} = useStaticQuery(graphql`
    {
      images: allFile(
        filter: {
          relativePath: {regex: "/instructions/"}
          name: {regex: "/big/"}
          extension: {eq: "jpg"}
        }
      ) {
        edges {
          node {
            childImageSharp {
              id
              gatsbyImageData(layout: FULL_WIDTH)
            }
            relativePath
            name
          }
        }
      }
    }
  `)

  const {
    node: {
      childImageSharp: {gatsbyImageData},
    },
  } = images.edges.find(({node: {name}}) => name.includes(`${index}-big`))
  return (
    <GatsbyImage
      image={gatsbyImageData}
      alt=""
      css={css`
        margin: 1rem 0 2rem;
        ${media(
          900,
          css`
            margin: 2rem 0 6rem;
            grid-column: 3 / -1;
          `
        )};
      `}
    />
  )
}

export default BigImg
