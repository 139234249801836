import React from 'react'
import {css} from '@emotion/react'
import {useStaticQuery, graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import {media} from 'styles'

const MomentImg = ({index, show}) => {
  const {images} = useStaticQuery(graphql`
    {
      images: allFile(
        filter: {
          relativePath: {regex: "/instructions/"}
          name: {regex: "/moment/"}
          extension: {eq: "jpg"}
        }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
          relativePath
          name
        }
      }
    }
  `)

  const image = images.nodes.find(({name}) => name.includes(`${index}-moment`))
  if (!image) {
    return null
  }

  return (
    <GatsbyImage
      alt=""
      image={image.childImageSharp.gatsbyImageData}
      style={{
        position: 'absolute',
        width: '25vw',
        height: 'auto',
        left: '70%',
        zIndex: '2',
      }}
      css={css`
        display: block;
        opacity: ${show ? 1 : 0};
        width: 100%;
        height: auto;
        ${media(900, {
          display: 'block',
        })};
      `}
    />
  )
}

export default MomentImg
