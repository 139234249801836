import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Intro from '../../../components/intro';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Intro;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Inhabit`}</h1>
    <p>{`There are`}<br parentName="p"></br>{`
`}{`two paths`}</p>
    <p>{`the end`}<br parentName="p"></br>{`
`}{`of the world`}</p>
    <p>{`or the`}<br parentName="p"></br>{`
`}{`beginning`}</p>
    <p>{`of the`}<br parentName="p"></br>{`
`}{`next`}</p>
    <p>{`You have to choose`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      