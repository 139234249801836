import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Path from '../../../components/two-paths/path-text';
import PathHero from '../../../components/two-paths/path-hero';
import Marquee from '../../../components/marquee';
export const _frontmatter = {
  "order": 0
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Deux Chemins`}</h2>
    <Marquee mdxType="Marquee">La fin du monde</Marquee>
    <PathHero id="a" mdxType="PathHero">
      <h3>{`Chemin A`}</h3>
      <p>{`C'est terminé`}<br parentName="p"></br>{`
`}{`Courbez la tête`}</p>
      <p>{`et`}</p>
      <p>{`faites défiler`}<br parentName="p"></br>{`
`}{`l'apocalypse sur`}<br parentName="p"></br>{`
`}{`votre téléphone`}</p>
    </PathHero>
    <Path id="a" mdxType="Path">
      <p>{`Voyez la Silicon Valley remplacer tout par des robots. De nouveaux cultes de mort fondamentalistes qui font passer ISIS pour un jeu d'enfant. Alors que les autorités sortent une appli de géolocalisation qui permet de dénoncer en temps réel les immigrants et les dissidents politiques, des méta-fascistes socio-financent les prochains camps de concentration. Les services gouvernementaux tombent en faillite. Les politiciens adoptent des mesures toujours plus draconiennes et la gauche continue de japper sans mordre.`}</p>
      <p>{`Tout ça alors que les glaciers fondent, les feux de forêt font rage, et encore une autre ville est noyée par l'ouragan je-ne-sais-quoi. Des maladies du passé refont surface avec le dégel du permafrost. Le travail n’en finit plus et les riches profitent des ruines. Au final, sachant que nous n'avons rien fait, `}<em parentName="p">{`nous périssons`}</em>{` et prenons place dans la même tombe que le reste de la vie sur terre.`}</p>
    </Path>
    <Marquee mdxType="Marquee">Le début du prochain</Marquee>
    <PathHero id="b" mdxType="PathHero">
      <h3>{`Chemin B`}</h3>
      <p>{`Inspirez`}</p>
      <p>{`et`}</p>
      <p>{`préparez-vous pour`}<br parentName="p"></br>{`
`}{`un monde nouveau`}</p>
    </PathHero>
    <Path id="b" mdxType="Path">
      <p>{`Une multiplicité de gens, d'espaces, d'infrastructures posent les fondations de puissants territoires autonomes. Tout pour tout le monde. La terre est restituée à l'usage commun. On cracke et on libère la technologie – tout redevient utile, tout devient une arme. Des chaînes d'approvisionnement autonomes brisent l'emprise de l'économie. Des réseaux maillés fournissent une communication en temps réel qui connecte ceux et celles qui sentent qu'une vie différente doit être construite.`}</p>
      <p>{`Alors que les gouvernements tombent en ruine, les territoires autonomes fleurissent, portés par le sentiment qu’il faut se lier à cette terre et à la vie qui y existe pour être libres. Les ressources des enclaves techno-féodales sont pillées. Nous confrontons les forces déclinantes de la contre-révolution avec cette seule option: `}<em parentName="p">{`l’utopie ou l’enfer`}</em>{`? `}</p>
      <p>{`Les deux réponses nous satisfont.`}</p>
      <p>{`Finalement, nous atteignons le seuil – nous pouvons sentir le danger de la liberté, l'étreinte de la vie en commun, le miraculeux et l'inconnu – et alors, nous savons: voilà la vie.`}</p>
    </Path>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      