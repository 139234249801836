import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Instructions from '../../../components/instructions';
export const _frontmatter = {
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Instructions;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Instructions`}</h2>
    <h3>{`1. Se trouver`}</h3>
    <p>{`Nous avons été élevées dans une culture d'isolement et de défaite, où notre potentiel se voit réduit aux exigences de l'économie. Ensevelies sous notre angoisse personnelle, nos factures et nos craintes, nous sommes forcées à ne nous soucier que de nous-mêmes. `}<em parentName="p">{`Mais nous sommes capables de vivre autrement.`}</em></p>
    <p><em parentName="p">{`Pour commencer, éliminer l'isolement.`}</em>{` Arrêter de déconner. Se tourner vers nos proches et leur dire que nous voulons vivre ensemble. Se demander à quoi pourrait ressembler le fait de faire face au monde ensemble. De quoi dispose-ton? De quoi a-t-on besoin? Faire un inventaire collectif de nos savoir-faire, nos capacités et nos liens. Prendre les décisions qui feront croître notre force. Jeter les bases d'une vie en commun.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Imaginez une vie qui franchirait les frontières individuelles. Changer la manière dont on se déplace, pour prendre la chance de rencontrer autre chose. Des rencontres passagères qui deviennent des relations profondes. Vous flânez dans votre quartier, et vous vous arrêtez dans les maisons des amies en route vers le café. Vous vous rencontrez le soir au parc pour vous entraîner. Vous rentrez ensemble à la maison. Vous partagez vos voitures. Vous allez faire du camping et apprenez ensemble à allumer un feu. Vous mettez de l'argent en commun en réserve pour les temps durs. L'idée de la propriété privée se brouille. Vous commencez à vous concevoir comme quelque chose de plus `}<em parentName="p">{`décisif`}</em>{` qu'un simple groupe d'amies.`}</p>
    </blockquote>
    <h3>{`2. Créer des hubs`}</h3>
    <p>{`Les hubs sont des points d'agrégat, des centres d'activité. Après s’être trouvées, la prochaine étape consiste à créer un hub. Nous devons avoir des espaces qui nous permettent de s’organiser et de passer du temps ensemble. Les hubs rassemblent les gens, les ressources et un certain esprit, qui sont nécessaires pour poser les fondements d'une vie en commun.`}</p>
    <p><em parentName="p">{`Mettre nos ressources en commun, cibler une région et y ouvrir un hub.`}</em>{` Louer un espace dans le quartier. Construire une cabane dans les bois. S’emparer d'un bâtiment abandonné ou d’un terrain vague. Aucun espace n'est trop petit ou trop ambitieux. Commencer avec ce qu’on a sous la main puis le faire proliférer. Faire du hub un point ancrage pour d’autres initiatives.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Une boutique reconvertie accueille des `}<em parentName="p">{`soupers hebdomadaires`}</em>{` qui se transforment en sessions de planification. Les profits d'un café autogéré sont mis de côté pour permettre d'ouvrir d'autres espaces, comme un établi de menuiserie où l'on pourra construire plus que des étagères. Dans une forêt à la sortie de la ville, à chaque semaine, on fait des feux et des entraînements d'arts martiaux dans une clairière qui sert de lieu de rencontre. Près de là, une ferme de permaculture s'agrandit lentement pour pouvoir nourrir ceux qui habitent en ville.`}</p>
    </blockquote>
    <h3>{`3. Devenir résilientes`}</h3>
    <p>{`Nos corps sont un mystère. Notre santé échappe à notre emprise. Si l'électricité était coupée, la plupart d'entre nous serions condamnées à l'obscurité. Nous avons été dépossédées de nos savoir-faire, de nos passions et nos connaissances. Mais nous ne sommes pas fragiles. Quand nous apprenons de nouvelles techniques ou surmontons des défis, nous repoussons les seuils qui déterminent notre sens des possibles. Nous sommes capables de prouesses incroyables et improbables.`}</p>
    <p><em parentName="p">{`Retrouver des savoir-faire, apprendre à les maîtriser et à partager leur puissance`}</em>{`. Contacter des gens qui ont des capacités qu’on voudrait voir se répandre chez tout un chacun. Faire de nos hubs des lieux d’expérimentation. Mettre en place ce qui bientôt deviendra normal. Apprendre à chasser, à encoder, à guérir : faire croître notre force collective.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Un ouragan dévaste la ville – `}<em parentName="p">{`le courant est coupé`}</em>{`. Le système national d'intervention d'urgence prends son temps. Un groupe établit un hub près d'une zone inondée. Avoir cuisiné ensemble des grands soupers a donné la confiance nécessaire à tout le monde pour opérer à plus grande échelle. Des équipes partent ensemble à la recherche de nourriture dans un environnement où il n'y a plus de loi, et combattent les opportunistes racistes qui s'accrochent à une ère de propriété dorénavant révoquée. Quelqu'un rassemble le matériel médical dans les hôpitaux et les pharmacies alors qu'une autre ouvre les réservoir d'eau des immeubles. Une occupation dans un parc rassemble encore plus de gens et de ressources. Quelqu'un escalade un édifice pour y placer un routeur à énergie cinétique. Le routeur établit une connexion avec un réseau maillé qui appelle au renfort des autres hubs parsemés à travers le territoire.`}</p>
    </blockquote>
    <h3>{`4. Partager un avenir`}</h3>
    <p>{`Le temps des vies isolées est terminé. Ce que nous avons en partage, c’est la catastrophe et les défis imposés par l’époque. Nous pouvons contester la distribution inégale des ressources médicales, mais le soin ne sera universel et digne que lorsqu’il sera autonome.`}</p>
    <p><em parentName="p">{`Créer des formes collectives de soin. S’organiser en vue des vingt prochaines années.`}</em>{` Se demander quels seront nos besoins quand on vieillira, quand on aura des enfants, quand on sera handicapées et qu'on commencera à mourir. Prendre des décisions basées sur nos désirs. Imaginer comment les espaces pourront s'adapter à la nature dynamique de la vie et de la lutte. Répondre aux questions les plus difficiles : comment faire face à la folie, à la dépendance, aux violences interpersonnelles, aux pertes traumatisantes. Se protéger, à tout prix, de l'institutionnalisation.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Un réseau intergénérationnel est formé pour partager tous les aspects de la vie. Les gens réfléchissent ensemble à comment élever des enfants, comment nourrir leur capacités, comment les aider à réagir au monde qui se transforme. On prends soin des aînés collectivement, la dignité de chaque étape de la vie est affirmée puisqu'on se nourrit de leurs expériences. Des collectifs de santé apprennent des méthodes ancestrales pour contrôler les naissances et faire des avortements afin de garantir `}<em parentName="p">{`l'autonomie de choix`}</em>{`. Une intelligence émotionnelle partagée aide ceux qui ont besoin de prendre une pause de la lutte et celles qui décident d'y retourner. Des médecins partisans, des herboristes et des chamanes font un pacte et s'engagent à fournir des soins à tout le réseau. Tout le monde se repose avec plus d'aisance, sachant que l'hôpital n'est plus la première option. Les services de l’État sont presque devenus inutiles. Son fardeau historique est progressivement levé grâce à une nouvelle approche de la vie et la mort. Sans les anxiétés et le stress de cette civilisation, les maladies commencent à disparaître. Cette nouvelle capacité à prendre soin devient un réservoir de force pour faire face à l'avenir en commun.`}</p>
    </blockquote>
    <h3>{`5. Engager la bataille`}</h3>
    <p>{`Notre société insulte celles et ceux qui défendent ce qui est juste. On nous dit que rien ne peut changer, qu’il faut se mêler de ses affaires, et, par-dessus tout, ne pas résister. Cultiver un esprit de lutte nécessite aujourd’hui de suivre une boussole éthique, tout en développant une pensée stratégique et une capacité physique.`}</p>
    <p><em parentName="p">{`Devenir plus forts`}</em>{`. Devenir capable de faire usage de la force. Apprendre l'art de la frappe, comment toute chose peut devenir une arme. Apprendre à subvertir la force de l'ennemi – comment un seul coup de poing devenu viral peut ébranler partout l'égo des fascistes, comment peut-on neutraliser collectivement l'ennemi en coupant son système de communication. Qu'est-ce qui se tiendra en travers du chemin d'un nouveau mode de vie? Comment peut-on le surmonter, ensemble? Quelles considérations stratégiques devons-nous cacher aux oreilles ennemies?`}</p>
    <blockquote>
      <p parentName="blockquote">{`Un réseau de `}<em parentName="p">{`clubs de combat`}</em>{` relie chaque grande ville. Des membres expérimentés enseignent des techniques de combat et entraînent les corps à gagner en force et en souplesse. Chaque club ouvre un espace et construit des liens avec la communauté locale, particulièrement avec les marginalisées de ce monde. Un chapitre du Midwest se mobilise avec des camionneurs pour résister à l'automatisation. Ensemble, ils paralysent l'autoroute 70 avec l'aide d'une application de géotracking, ils bloquent des camions auto-pilotés et s'emparent de leur marchandise. Ce qui est utile est exproprié et le reste est réduit en cendres. La fumée aveugle les voitures des policiers qui sont déjà perdus au milieu des barricades de fortune. Les cargos relâchent un essaim de mini-drones, qui volent en formation défensive, contrôlés par une application. Les drones sont hackés puis vont infiltrer ceux de la police pour leur transmettre un virus qui fige leurs propulseurs. Ils sont rendus inoffensifs et tombent au sol. En profitant du chaos, les camionneurs et les combattants du club passent à l'offensive et parviennent à s'enfuir.`}</p>
    </blockquote>
    <h3>{`6. Étendre le réseau`}</h3>
    <p>{`Nous n'avons pas besoin d'une autre organisation qui nous rassemble pour parler de nos problèmes, mais bien de manières d'implanter des pratiques concrètes pour les régler. Nous avons besoin d'un réseau qui amplifie la puissance de chaque projet, qui élargisse le territoire et refuse de laisser le futur aux mains du hasard.`}</p>
    <p><em parentName="p">{`Trouvez-vous sur une large échelle`}</em>{`. Cherchez les gens qui s'organisent comme vous. Repérez les intensités naissantes et les formes communales et entrez en contact. Interpellez-les, établissez des communications, visitez-vous et rencontrez-vous. Échangez histoires et stratégies, pour enrichir la mémoire culturelle de notre réseau et son intelligence tactique, et entretenir notre puissance collective. Créez des liens matériels, partagez ou échangez des ressources. Multipliez ces gestes par milliers.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Sur le territoire subverti, des biohackers expérimentent avec des nouvelles techniques, inventent de nouveaux `}<em parentName="p">{`purificateurs d'eau`}</em>{`, un groupe de familles autochtones résiste à une compagnie énergétique qui veut s'emparer de leur terre sacrée, et un hub autonome redéfinit son quartier avec une série de fermes urbaines. Des communications régulières entre ces trois projets permettent d'adresser leurs besoins communs. Les techniques de traitement d'eau se répandent, alors que des infrastructures alimentaires autonomes entraînent une abondance de vivres. Le réseau est mobilisé lorsque les familles autochtones demandent du renforcement pour défendre leur terre. Grâce à une coordination logistique encryptée, des milliers de personnes peuvent s'y rendent avec suffisamment des ressources pour soutenir la lutte.`}</p>
    </blockquote>
    <h3>{`7. Construire l'autonomie`}</h3>
    <p>{`On nous a appris à dépendre des chèques de paye et des magasins pour vivre. Le système capitaliste ne nous laisse qu’un choix : nous soumettre ou mourir de faim. Il n'y a pas moyen d'échapper à cette réalité: l'organisation matérielle du monde est le problème qu’il s’agit de surmonter.`}</p>
    <p><em parentName="p">{`Augmenter la portée des initiatives autonomes.`}</em>{` Construire les infrastructures nécessaires pour soustraire les territoires à l'économie. Répondre aux problèmes posés par la puissance matérielle collective : comment se nourrir, s'héberger, se soigner. Utiliser autant le data que le design tout en évitant le piège de croire qu’Internet est là pour nous sauver. Former des collectifs et des coopératives, les amener à atteindre des objectifs stratégiques sans succomber au vide de l'économie. Développer des solutions face aux problèmes de l’énergie, de la distribution, des communications et de la logistique, qui puissent être diffusées et adaptées à d’autres situations.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Un hub local de distribution de nourriture ouvre une épicerie coopérative de l'autre côté de la ville. La ferme agricole voisine qui les fournit a besoin d'agrandir sa capacité. Elle rejoint un réseau biorégional, autant pour partager une réalité que des aliments frais. Un groupe de designers et d'ingénieurs qui détestent leur boulot forment une équipe et créent une application qui coordonne une `}<em parentName="p">{`chaîne d'approvisionnement`}</em>{` flexible entre les fermes et les points de distribution. Ces efforts mènent à un corridor d'échanges autonome. Les autorités se retrouvent incapables de faire face à la croissance du réseau et à son mépris des normes, alors que la nourriture et les gens circulent librement dans un esprit de rébellion.`}</p>
    </blockquote>
    <h3>{`8. Destituer les infrastructures`}</h3>
    <p>{`Nous ne voulons pas seulement améliorer la vie de quelques heureux élus – il s'agit d’un exode de masse hors de ce monde. Cela exige de faire face aux infrastructures qui soutiennent cette civilisation, pour les convertir à notre gré. Certains systèmes devront être démantelés, comme les oléoducs de pétrole et les centrales nucléaires, tandis que d'autres pourront être transformés pour servir l'autonomie.`}</p>
    <p><em parentName="p">{`Détourner l’ancien en vue de l’actuel`}</em>{`. Il convient non seulement de trouver des solutions à ce que les infrastructures présentes n’arrivent pas à confronter, mais aussi de réquisitionner les institutions existantes pour en modifier radicalement les usages. Occuper les espaces étouffants – les hôtels de ville, les écoles, les centres d'achat – leur insuffler une nouvelle vie. Anticiper et intensifier les fractures stratégiques. Rediriger les systèmes de communication. Réquisitionner les chaînes d'approvisionnement. Saisir le pouvoir – sans gouverner.`}</p>
    <blockquote>
      <p parentName="blockquote">{`La prolifération de cliniques médicales autonomes gagne en influence sur tous les fronts de la médecine. Les infirmières, les docteurs et les administrations travaillent ensemble pour détourner clandestinement les équipements médicaux vers ces cliniques. Quand le gouvernement coupe son financement des hôpitaux pour vétérans, les cliniques autonomes se lient avec les patients et ceux qui fournissent les soins de santé pour occuper les bureaux des Services aux Anciens combattants à travers le pays. La répression brutale d’une occupation envoie des douzaines de camarades à l’hôpital, mais quand la police tente d'entrer dans les soins intensifs pour arrêter les vétérans blessés, ils sont expulsés par les chirurgiennes et les infirmiers. Les groupes autonomes sont rejoints par les forces qui affluent des occupations et des hôpitaux, et des ressources vitales sont pillées pour l'insurrection en cours.`}</p>
    </blockquote>
    <h3>{`9. Devenir ingouvernables`}</h3>
    <p>{`La révolution est une ligne tracée dans le présent.`}</p>
    <p>{`Cela implique de construire l'autonomie ici et maintenant, de rendre superflue autant l'économie que le gouvernement. Sortir de l'état d'être gouvernées exige bien plus que de gagner bataille après bataille, bien plus que de déjouer nos ennemis politiques. Cela reposera sur notre capacité à créer les bases durables d'une vie en commun.`}</p>
    <p><em parentName="p">{`Propager la sécession à tous les aspects de la vie`}</em>{`. Faire une grève éternelle, lentement mais sûrement, en ne laissant personne derrière. Refuser d'être gérées, ou de gérer qui que ce soit à son tour. Creuser une brèche au coeur même de la société. Désavouer une vie entière de cynisme et de ressentiment. Croire que tout cela est possible.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Les grèves persistent,  le fade fardeau de la dette se désintègre, alors même que le capital financier s'effondre sous l'hostilité grandissante. Les assemblées de quartier décident comment agir en cas d'urgence, des soldats rebelles refusent de tirer sur leurs propres voisins, et le 'crime' se voit relégué à quelques raids en zones gouvernées. Dans les métropoles, chaque jour est une fête de rue. Des repas en plein air dans les rues bondées proclament un temps où la vie économique n’est plus qu’un souvenir, alors qu’on invente de nouveaux usages aux magasins. La nuit, des feux de joie éclairent le ciel et les sages étoiles réapparaissent pour nous protéger. Dans les banlieues, on transforme un Walmart en hub de marchandises et d’organisation. Des camionneurs et des premiers répondants se coordonnent pour apporter de l'aide à un territoire inondé. Dans l'Ouest, des technologues équipent des ballons météo avec des émetteurs-récepteurs pour amplifier l'internet autonome. Le travail, libéré de l'économie, augmente le rendement des fermes autonomes, et les enfants apprennent ce qu’est la loyauté à la terre.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      