import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {type, colors, outline, media} from 'styles'

const Heading = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  h2 {
    opacity: ${(props) => (props.active === props.index ? 1 : 0)};
    font-family: ${type.akzidenz};
    font-size: 4vw;
    writing-mode: vertical-rl;
    color: ${colors.orange};
    ${media(760, outline(colors.orange))};
  }
`

const Headings = ({headings, active}) => (
  <div
    hidden
    css={css`
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 2rem;
      ${media(760, {right: '2.4rem'})};
      ${media(900, {right: '6rem'})};
    `}
  >
    {headings.map((heading, i) => (
      <Heading key={i} active={active} index={i}>
        <h2>{heading}</h2>
      </Heading>
    ))}
  </div>
)

export default Headings
