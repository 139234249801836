import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Now from 'components/section-now';
export const _frontmatter = {
  "order": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Now;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Now`}</h2>
    <p><em parentName="p">{`There is no future emergency for which we must prepare.`}</em></p>
    <p>{`We are already here—with every dystopian element, every means of revolution.
The horrific consequences of our time and its beautiful potential are
unfolding everywhere. We are resisting the end of the world by proliferating
new worlds. We are becoming ungovernable—unbeholden to their merciless law,
their crumbling infrastructure, their vile economy, and their spiritually
broken culture.`}</p>
    <p><em parentName="p">{`We violently stake a claim in happiness`}</em>{`—that life resides in our material
power, in our refusal to be managed, in our ability to inhabit the earth,
in our care for each other, and in our encounters with all forms of life
that share these ethical truths.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      