import './src/styles/index.css'
import Marquee3k from 'marquee3000'

const scrollTo = (id) => {
  const el = document.getElementById(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

function initializeMarquee3k() {
  document.fonts.ready.then(() => {
    Marquee3k.init()
  })
}

export const onInitialClientRender = () => initializeMarquee3k()
export const onRouteUpdate = ({location: {hash}}) => {
  initializeMarquee3k()
  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }

  if (
    process.env.NODE_ENV === `production` &&
    typeof window.plausible === `object`
  ) {
    window.plausible('pageview')
  }
}
