import {useStaticQuery, graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
export default function PathImage({id}) {
  const {images} = useStaticQuery(graphql`
    {
      images: allFile(
        filter: {name: {regex: "/path/"}, extension: {eq: "png"}}
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(width: 220, placeholder: NONE, layout: FIXED)
          }
          name
        }
      }
    }
  `)
  const {
    childImageSharp: {gatsbyImageData},
  } = images.nodes.find(({name}) => name.includes(`path-${id}`))

  return (
    <div className="grid-span-full mb-6">
      <GatsbyImage
        image={gatsbyImageData}
        alt=""
        className="m-auto md:mr-0 md:ml-auto"
      />
    </div>
  )
}
