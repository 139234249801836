const {jsx} = require('@emotion/react')
const slugify = require('slugify')

function usePlausible() {
  return function (eventName, ...rest) {
    return window.plausible?.(eventName, rest[0])
  }
}

/**
 * @param {Array} nodes data returned from GraphQL query
 * Builds localized path object for looking up equivalent pages in other languages
 */
const getLocalizedPaths = (nodes) => {
  return nodes.reduce((acc, node) => {
    const {lang, slug} = node.fields
    const {id} = node.frontmatter
    if (!acc[lang]) {
      acc[lang] = {}
    }

    acc[lang][id] = slug
    return acc
  }, {})
}

const cloneElement = (element, props) =>
  jsx(element.type, {
    key: element.key,
    ref: element.ref,
    ...element.props,
    ...props,
  })

const getHeading = (node) => {
  return node.headings.map(({value}) => value)
}

const getHeadings = (allMdx, lang) =>
  allMdx.nodes
    .filter((node) => {
      if (lang) {
        return node.fields.lang === lang
      }
      return true
    })
    .sort((a, b) => {
      if (!a.frontmatter || !b.frontmatter) {
        return true
      }
      return a.frontmatter.order > b.frontmatter.order ? 1 : -1
    })
    .map(getHeading)
    .flat()

const getSlug = (str) => slugify(str, {lower: true, remove: /\./})

module.exports = {
  getLocalizedPaths,
  cloneElement,
  getHeadings,
  getSlug,
  usePlausible,
  localizedPaths: {
    en: {read: '/read', label: 'English'},
    es: {read: '/es', label: 'Español'},
    fr: {read: '/fr', label: 'Française'},
    it: {read: '/it', label: 'Italiano'},
    pl: {read: '/pl', label: 'Polski'},
  },
}
