import React from 'react'
import {css} from '@emotion/react'
import VisuallyHidden from '@reach/visually-hidden'
import {type, colors} from 'styles/index'

const Marquee = ({children, inverse}) => (
  <div
    css={css`
      font-family: ${type.lydia};
      color: ${inverse ? 'white' : colors.orange};
      background: ${inverse ? colors.orange : 'transparent'};
      font-size: 1.6rem;
      position: relative;
      overflow: hidden;
      margin: 2rem 0;
      padding: 1rem 0;
      grid-column: 1 / -1;
    `}
  >
    <VisuallyHidden>{children}</VisuallyHidden>
    <div
      data-speed="0.5"
      data-pausable
      className="marquee3k"
      css={{textAlign: 'center'}}
    >
      <span aria-hidden hidden>
        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{children}
        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
      </span>
    </div>
  </div>
)

export default Marquee
