import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import OurTime from '../../../components/our-time';
import Smudge from '../../../components/smudge';
export const _frontmatter = {
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = OurTime;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nuestro Tiempo`}</h2>
    <Smudge mdxType="Smudge" />
    <h3>{`Nuestro Tiempo Es Tumultuosos Y Potente.`}</h3>
    <p><em parentName="p">{`Levantamientos, polarización, la política tan en quiebra como los mercados financieros ­ aún así`}</em>{`, bajo la crisis yace la posibilidad. Esta época nos fuerza a considerar cómo cada uno de nosotros forma un grano de potencial, cómo los individuos pueden seguir sus inclinaciones más descabelladas de reunirse con otrxs que escuchen el llamado. Gente aprende habilidades perdidas y guerrerxs le regresan el fuego al mundo. Granjerxs y jardinerxs experimentan con agricultura orgánica, mientras hackers y hacendosxs reconfiguran máquinas. Modelos escapan la vacía luz de calcio y comparten pan con radicales Kurdos y militares veteranos en defensa de la vida comunal. `}</p>
    <p>{`Lxs que no tienen uso para la política se encuentran unxs a otrxs a la mesa del comedor en el Parque Zucotti, la Plaza Oscar Grant, o Tahrir Square; y la barista que sola apenas puede alimentarse a sí misma aprende a cocinar para miles en conjunto. Una soldadora retirada y un diseñador web descubren que son vecinos en la ocupación de un aeropuerto y se comprometen a leer El Arte de la Guerra juntxs. Una estrella de Instagram cuya ansiedad usualmente la mantiene confinada a su departamento conoce a un anciano aguerrido en Ferguson, donde son bautizadxs en gas lacrimógeno y fuerza colectiva, y comienzan a sentir el peso levantado de sus almas.`}</p>
    <p>{`Gente en todos lados, viviendo a través del aislamiento más grande, se alzan juntxs y encuentran nuevos modos de vida. Pero cuando estos granos crecen a la superficie, son estampados en un frenesí de banalidad y miedo. Aperturas son forzosamente cerradas por la policía antidisturbios, fuerzas de seguridad privada y firmas de relaciones públicas. O peor, por lxs solitarixs – políticamente de derecha o izquierda – quienes no tienen nada que ganar más que otro 'like' en su twitter de porquería. Todo esto mientras políticos presumidos y CEOs se pasean por encima. El carácter revolucionario de nuestra época no puede ser negado, pero aún nos hace falta superar la barrera entre nosotrxs y la libertad.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Venimos de un lugar roto, pero seguimos en pie.`}</h3>
    <p><em parentName="p">{`El nihilismo de nuestra época es topológico.`}</em>{` Todos lados carecen de cimientos. Buscamos el poder organizacional para reparar el mundo y sólo encontramos instituciones llenas de cinismo y debilidad. Activistas con buenas intenciones son digeridos a través del cuerpo sin espina de la política convencional, dejando militantes deprimidos o mini­políticos. Aquellxs que hablan en contra del abuso terminan siendo testigos de tristes juegos de poder en las redes sociales. Movimientos hacen erupción y luego implosión, devorados internamente por parásitos.`}</p>
    <p>{`Ciudades se vuelven inhabitables al subir los niveles del agua, mientras los gobiernos se hacen pelotas por mantener su legitimidad. Cada desastre se siente más y más íntimo. Ya sea que lo veamos en el internet, o que recibamos el temido texto: `}<em parentName="p">{`ya escuchaste?`}</em>{` Accidentes se sienten como masacres. Los nombres de lxs muertxs, un índice de una civilización en declive. Hemos perdido amigxs y familiares por causa de adicción, pobreza y desesperación. Hemos observado a la policía ejercer su derecho de matar, sin saber cómo saciar nuestra rabia. Nos sostuvimos en conjunto a través de todo, y aquí seguimos. Sentimos el presente que nos han robado, imaginamos el futuro que nos depara el destino. Nadie vendrá a salvarnos. Tenemos que darnos a la tierra donde una revolución crecerá.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Tenemos el poder de hacer una ruptura permanente.`}</h3>
    <p><em parentName="p">{`Nos levantamos día tras día,`}</em>{` generación tras generación, yendo al trabajo para recalibrar la misma pesadilla que nos fuerza a trabajar. Nos esforzamos por sobrevivir, sentimos el estrés del transporte diario, vivimos de quincena a quincena, de un trabajillo precario a otro, todo para que no nos corten el agua. Nuestra labor hizo este mundo y lo mantiene andando, pero ninguno de nosotros se siente en casa. No sorprende que tanta gente se lance a cualquier cosa que prometa que pueda ser mejor – movimientos, modas de salud, subculturas, milicias, pandillas, lo que sea.`}</p>
    <p>{`Queremos una vida digna. Deseamos la libertad de poner nuestras manos callosas hacia la experimentación, hacia convertirnos en mucho más que nuestro empleo. Si la potencia de nuestro tiempo es alguna indicación de algo, es de que somos capaces de más que mera supervivencia. La propia labor que brindamos – nuestra fuerza, creatividad, e inteligencia – puede ser nuestra arma. La posibilidad de perdurar está en nuestra capacidad de atacar y en la seducción de nuestro poder compartido. Nuestro ataque será la práctica inmediata de reconfigurar cómo vivimos, sin respeto a nuestros jefes, los ricos, o los robots diseñados para reemplazarnos. Juntos tenemos el conocimiento y la voluntad de construir una mejor vida, una vida en nuestros propios términos, y depende de nosotros crear y habitar nuevos mundos que reemplacen este. Nuestro ingenio, pasión, determinación – somos la pieza de la cual cada futuro depende.`}</p>
    <Smudge mdxType="Smudge" />
    <h3>{`Nada hace falta, Miren alrededor. Denle Forma.`}</h3>
    <p><em parentName="p">{`Pieza por pieza, estamos ensamblando los cimientos de una fuerza revolucionaria.`}</em>{` Estamos construyendo una vida en común, combatiendo la pobreza material y espiritual impuesta a nosotros por la época, y abriéndonos a la experimentación inmediata de diferentes maneras de vivir. Nuestra meta es establecer territorios autónomos – expandiendo zonas ingobernables que van de mar a mar. Fallas a lo largo de Norteamérica nos llevan a la providencia. Estos territorios autónomos darán paso a nuevos flujos de viajes y recursos, orientaciones durante las crisis ecológicas, y el terreno para reclamar las técnicas y tecnologías de las cuales nos han privado.`}</p>
    <p>{`Visualizamos nuestra tarea con serenidad y severidad. Queremos territorios con infraestructura flexible a la catástrofe, nacidos de la alegría colectiva. Habitados por una forma de vivir valiente y digna. Nuestro tiempo es diferente al pasado, y no esperaremos a que una senil nostalgia radical nos alcance. Revolución es una línea que trazamos en el presente. No tenemos cada respuesta, pero compartimos todo lo que sabemos que es verdad.`}</p>
    <p>{`Ahora es el momento de salir de esta insostenible forma de vivir.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      