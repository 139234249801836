import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Now from 'components/section-now';
export const _frontmatter = {
  "order": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Now;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Maintenant`}</h2>
    <p><em parentName="p">{`Il n'y a pas d'urgence future à laquelle se préparer`}</em>{`.`}</p>
    <p>{`Nous y sommes déjà – avec tous les détails dystopiques, et tous les moyens de faire la révolution. Les conséquences terribles de notre temps et son potentiel magnifique se déploient de toutes parts. Nous résistons à la fin du monde en faisant proliférer de nouveaux mondes. Nous devenons ingouvernables – nous ne sommes plus redevables à leurs lois sans merci, à leurs infrastructures décrépies, à leur économie abjecte et à leur culture spirituellement nulle.`}</p>
    <p><em parentName="p">{`Nous revendiquons le bonheur avec acharnement`}</em>{`—que la vie réside dans notre puissance matérielle, dans notre refus à d’être géré·es, notre rencontre avec toutes les formes de vie qui partagent ces vérités éthiques, notre capacité d’habiter la terre et de nous soigner mutuellement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      