import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {media} from 'styles'

const Smudge = styled.div`
  position: relative;
  grid-column: 1 / -1;

  &::after {
    position: absolute;
    content: '';
    width: 80vw;
    height: 100vh;
    z-index: -1;
    background: radial-gradient(
      circle at left,
      rgba(53, 83, 59, 0.7) 0%,
      rgba(255, 255, 255, 0) 50%
    );

    ${media(
      760,
      css`
        width: 800px;
        height: 1000px;
        top: -80px;
      `
    )};
  }

  &:nth-of-type(even)::after {
    right: 0px;
    left: auto;
    background: radial-gradient(
      circle at right,
      rgba(53, 83, 59, 0.7) 0%,
      rgba(255, 255, 255, 0) 50%
    );

    ${media(
      760,
      css`
        top: -300px;
      `
    )};
  }
`

export default Smudge
