import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Intro from '../../../components/intro';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Intro;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Inhabit`}</h1>
    <p>{`Ci sono due`}<br parentName="p"></br>{`
`}{`strade`}</p>
    <p>{`la fine`}<br parentName="p"></br>{`
`}{`del mondo`}</p>
    <p>{`o`}<br parentName="p"></br>{`
`}{`l'inizio`}</p>
    <p>{`del`}<br parentName="p"></br>{`
`}{`prossimo`}</p>
    <p>{`Si deve scegliere`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      