import React from 'react';
import IntroFr from "../../../../src/content/fr/read/_intro.mdx";
import TwoPathsFr from "../../../../src/content/fr/read/_two-paths.mdx";
import OurTimeFr from "../../../../src/content/fr/read/_our-time.mdx";
import InstructionsFr from "../../../../src/content/fr/read/_instructions.mdx";
import NowFr from "../../../../src/content/fr/read/_now.mdx";
import ReadFr from "../../../../src/components/read";
export default {
  React,
  IntroFr,
  TwoPathsFr,
  OurTimeFr,
  InstructionsFr,
  NowFr,
  ReadFr
};