import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Now from 'components/section-now';
export const _frontmatter = {
  "order": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Now;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Adesso`}</h2>
    <p><em parentName="p">{`Non c’è un’emergenza futura per la quale dobbiamo prepararci.`}</em></p>
    <p>{`Ci siamo già—con tutti gli elementi distopici, con tutti gli strumenti per la rivoluzione. Le terribili conseguenze del nostro tempo e il suo meraviglioso potenziale si stanno sviluppando e svelando ovunque. Stiamo resistendo alla fine del mondo, facendo proliferare mondi nuovi. Stiamo diventando ingovernabili—slegati dalla loro legge senza pietà, dalla loro infrastruttura al collasso, dallo loro vile economia e dalla loro cultura, ormai svuotata.`}</p>
    <p><em parentName="p">{`Rivendichiamo violentemente il nostro diritto alla felicità`}</em>{`—che la vita sta nella nostra forza materiale, nel nostro rifiuto di essere controllati e gestiti, nella nostra capacità di abitare questo mondo, nella nostra cura reciproca per gli altri, e negli incontri con tutte le forme di vita che condividono queste verità etiche.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      