import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Intro from '../../../components/intro';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Intro;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Habitar`}</h1>
    <p>{`Hay dos`}<br parentName="p"></br>{`
`}{`caminos`}</p>
    <p>{`el fin`}<br parentName="p"></br>{`
`}{`del mundo`}</p>
    <p>{`o el`}<br parentName="p"></br>{`
`}{`incio`}</p>
    <p>{`del`}<br parentName="p"></br>{`
`}{`siguiente`}</p>
    <p>{`Usted debe elegir`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      