import React from 'react'
import {css} from '@emotion/react'
import {useState} from 'react'
import {Waypoint} from 'react-waypoint'
import SectionHeadings from 'components/section-headings'
import {getSlug} from 'utils'
import {colors} from 'styles/'

const Read = ({children, headings}) => {
  const [currentSection, setCurrentSection] = useState(0)
  // grab children out of react fragment
  const components = children.props.children
  return [
    <SectionHeadings
      key="headings"
      active={currentSection}
      headings={headings}
    />,
    components.map((child, index) => {
      const id = index > 0 ? getSlug(headings[index - 1]) : 'intro'
      return (
        <section
          id={id}
          key={id}
          css={css`
            position: relative;
            background-color: ${colors.white};
            overflow: auto;
            &::before {
              content: '';
              display: block;
              margin-top: -63px;
              height: 63px;
            }
          `}
        >
          {index > 1 && (
            <Waypoint
              onEnter={({previousPosition}) => {
                if (previousPosition === 'below') {
                  setCurrentSection(currentSection + 1)
                }
                if (previousPosition === 'above' && currentSection > 0) {
                  setCurrentSection(currentSection - 1)
                }
              }}
              scrollableAncestor="window"
              topOffset={80}
            />
          )}
          {child}
        </section>
      )
    }),
  ]
}

export default Read
