import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Now from 'components/section-now';
export const _frontmatter = {
  "order": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Now;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Ahora`}</h2>
    <p><em parentName="p">{`No hay emergencia futura para la cual prepararnos.`}</em></p>
    <p>{`Ya estamos aquí – con cada elemento distópico, cada medio de revolución. Las consecuencias horripilantes de nuestro tiempo y su hermoso potencial se desenvuelven por doquier. Estamos resistiendo el fin del mundo al proliferar nuevos mundos. Nos estamos volviendo ingobernables – desagradecidos de su ley sin piedad, su infraestructura quebradiza, su vil economía, y su cultura espiritualmente rota.`}</p>
    <p><em parentName="p">{`Violentamente reivindicamos la felicidad`}</em>{`—que la vida reside en nuestro poder material, en nuestro rechazo a ser administrados, en nuestra habilidad de habitar la tierra, en nuestro cuidado mutuo, y en nuestros encuentros con todas las formas de vida que comparten estas verdades éticas.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      